import axios from "axios";
import React, { useState, useEffect } from "react";
import checkNetworkStatus from "../reusable/NetworkStatus";
import { getAccessStoreToken } from "../../components/utils/CookieUtils";
import AddCustomer from "./AddCustomer";
import ListCustomer from "./ListCustomer";
import ErrorMsg from "../messages/ErrorMessages";
import SuccessMsg from "../messages/SuccessMessages";
import uRLs from "../utils/BaseUtils";
import useShowAlert from "../utils/AlertUtils";

const Customer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCustomerData, setCustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isbtnLoading, setButtonLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const accessStoreToken = getAccessStoreToken();
  const showAlert = useShowAlert();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const listCustomer = async () => {
    checkNetworkStatus();
    try {
      if (!accessStoreToken) {
        showAlert("warning", ErrorMsg.TOKEN_MISSING);
        return;
      }
      const data = { searchtxt: "" };
      const response = await axios.post(`${uRLs.API_URL}list-customer`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });
      setCustomerData(response.data.results);
      setFilteredData(response.data.results);
      return true;
    } catch (error) {
        if (error.message === "NO_INTERNET") {
          showAlert("error", ErrorMsg.NO_INTERNET);
        } else if (error.code === "ERR_NETWORK") {
          showAlert("error", ErrorMsg.SERVER_DOWN);
        } else if (error.response && error.response.data?.message) {
            if(!error.response.data.code === 'NOT_FOUND') {
              showAlert("warning", error.response.data.message);
            }
        } 
        else if (error.code === 'ERR_BAD_REQUEST')  {
          showAlert("warning", ErrorMsg.URL_NOT_FOUND);
        } else {
          showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
        }
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newCustomerData) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const data = { ...newCustomerData };
      const response = await axios.post(`${uRLs.API_URL}add-customer`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });
      showAlert("success", SuccessMsg.CUSTOMER_ADDED);
      setCustomerData((prevCustomers) => {
        const updatedCustomers = [response.data.results, ...prevCustomers];
        setFilteredData(updatedCustomers);
        return updatedCustomers;
      });
      handleCloseModal();
      return true;
    } catch (error) {
        if (error.message === "NO_INTERNET") {
          showAlert("error", ErrorMsg.NO_INTERNET);
        } else if (error.code === "ERR_NETWORK") {
          showAlert("error", ErrorMsg.SERVER_DOWN);
        } else if (error.response && error.response.data?.message && !error.response.data.results.msg) {
            if(!error.response.data.code === 'NOT_FOUND') {
              showAlert("warning", error.response.data.message);
            }
        } else if (error.response && error.response.data?.message && error.response.data.results.msg) {
          showAlert("warning", error.response.data.results.msg);
        } 
        else if (error.code === 'ERR_BAD_REQUEST')  {
          showAlert("warning", ErrorMsg.URL_NOT_FOUND);
        } else {
          showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
        }
      return false;
    } finally {
      setButtonLoading(false);
    }
  };

  const handleEdit = async (updatedCustomer) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const data = { ...updatedCustomer, customerid: updatedCustomer._id };
      const response = await axios.post(`${uRLs.API_URL}update-customer`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });
      showAlert("success", SuccessMsg.CUSTOMER_UPDATED);
      setCustomerData((prevCustomerData) => {
        const updatedCustomers = prevCustomerData.map((customer) =>
          customer._id === response.data.results._id
            ? response.data.results
            : customer
        );
        setFilteredData(updatedCustomers); 
        return updatedCustomers;
      });
    } catch (error) {
      if (error.message === "NO_INTERNET") {
        showAlert("error", ErrorMsg.NO_INTERNET);
      } else if (error.code === "ERR_NETWORK") {
        showAlert("error", ErrorMsg.SERVER_DOWN);
      } else if (error.response && error.response.data?.message && !error.response.data.results.msg) {
          if(!error.response.data.code === 'NOT_FOUND') {
            showAlert("warning", error.response.data.message);
          }
      } else if (error.response && error.response.data?.message && error.response.data.results.msg) {
        showAlert("warning", error.response.data.results.msg);
      } 
      else if (error.code === 'ERR_BAD_REQUEST')  {
        showAlert("warning", ErrorMsg.URL_NOT_FOUND);
      } else {
        showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const handleDelete = async (customerId) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const data = { customerid: customerId };
      await axios.post(`${uRLs.API_URL}delete-customer`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });
      showAlert("success", SuccessMsg.CUSTOMER_DELETED);
      setCustomerData((prevCustomerData) => {
        const updatedCustomers = prevCustomerData.filter(
          (customer) => customer._id !== customerId
        );
        setFilteredData(updatedCustomers); // Sync filtered data
        return updatedCustomers;
      });
    } catch (error) {
      if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
        showAlert("error", ErrorMsg.NO_INTERNET);
      } else {
        showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = isCustomerData.filter((customer) =>
      Object.values(customer)
        .join(" ")
        .toLowerCase()
        .includes(value)
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    listCustomer();
  }, [accessStoreToken]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="page-breadcrumb d-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Customers</div>
            <div className="ms-auto">
              <button
                type="button"
                onClick={handleOpenModal}
                className="btn btn-primary mt-2 mt-lg-0"
              >
                <i className="ng-ng-customers-add-icon"></i> Add Customer
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="card radius-10 mb-0">
                <div className="card-body px-0">
                  <div className="row px-3">
                    <div className="col-lg-5 me-auto">
                      <div className="ms-auto position-relative">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search customers by name, phone, etc..."
                            value={searchTerm}
                            onChange={handleSearch}
                          />
                          <button className="input-group-text">
                            <i className="ng-ng-search-icon"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <AddCustomer
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onAdd={handleAdd}
                    isbtnLoading={isbtnLoading}
                  />

                  <ListCustomer
                    isLoading={isLoading}
                    isCustomerData={filteredData} // Pass filteredData
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;