import React from 'react'
import { Skeleton } from '@mui/material';
import CurrencyConverter from '@heartinz/currency_subunit_converter';

function ListRecentProduct({ isData, isLoading }) {
  return (
    <div>
      <div className="table-responsive">
        <table className="table align-middle mb-0">
          <thead className="table-light text-center">
            <tr>
              <th>S.No</th>
              <th>Barcode</th>
              <th>Title</th>
              <th>Sale Price</th>
              <th>Tax</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [...Array(10)].map((_, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <Skeleton variant="text" width={200} />
                  </td>

                  <td className="text-center">
                    <Skeleton variant="center" width={200} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                </tr>
              ))
            ) :
              Array.isArray(isData) && isData.length > 0 ? (
                isData.map((item, index) => (
                  <tr key={item.id || index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      <span className="badge rounded-pill bg-label-blue">{item.code}</span>
                    </td>
                    <td className="text-center" width="400">
                      <span className="text-heading fw-medium text-truncate">
                        {item.title}
                      </span>
                    </td>
                    <td className="text-center">₹{CurrencyConverter.toCurrency(item.saleprice, 'INR')}</td>
                    <td className="text-center">
                      <span className="badge bg-light-purple">{item.taxpercentage}%</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center p-2">
                    <span className="text-muted">No product found in the store. add some product to get started.</span>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ListRecentProduct
