import React, { useState, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import { SnackbarProvider } from "./contexts/SnackbarContext";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import SnackbarComponent from "./components/reusable/SnackbarComponent";
import NetworkStatusMonitor from "./components/utils/NetworkStatusUtils";
import Login from "./components/login/Login";
import Header from "./components/layout/Header";
import SideMenu from "./components/layout/SideMenu";
import Menu from "./components/layout/Menu";
import Dashboard from "./components/dashboard/Dashboard";
import Customer from "./components/customer/Customer";
import Product from "./components/product/Product";
import ListProduct from "./components/product/ListProduct";
import Sale from "./components/sale/Sale";
import ListSale from "./components/sale/ListSale";
import InviteSub from "./components/login/InviteSub";
import InviteHub from "./components/login/InviteHub";
import SalesReport from "./components/reports/SalesReport";
import FullPageLoader from "./components/reusable/FullPageLoader";

function App() {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const location = useLocation();
  const hideLayoutRoutes = ["/login", "/invite-sub", "/invite-hub"];
  const shouldHideLayout = hideLayoutRoutes.some(route => location.pathname.startsWith(route));

  const toggleSideMenu = () => {
    setSideMenuOpen(!isSideMenuOpen);
  };

  const LoginRedirect = () => {
    return isAuthenticated ? <Navigate to="/dashboard" /> : <Login />;
  };

  return (
    <SnackbarProvider>
      <SnackbarComponent />
      <NetworkStatusMonitor />
      {!shouldHideLayout && (
        <>
          <Header toggleSideMenu={toggleSideMenu} />
          {isSideMenuOpen && <SideMenu toggleSideMenu={toggleSideMenu} />}
          <Menu />
        </>
      )}
      <div>
        {isLoading && <FullPageLoader isLoading={isLoading} />}
        <Routes>
          <Route path="/login" element={<LoginRedirect />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/invite-sub/:token"
            element={<InviteSub />}
          />
          <Route
            path="/invite-hub/:token"
            element={<InviteHub />}
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer"
            element={
              <ProtectedRoute>
                <Customer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product"
            element={
              <ProtectedRoute>
                <Product />
              </ProtectedRoute>
            }
          />
          <Route
            path="/listproduct"
            element={
              <ProtectedRoute>
                <ListProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sales"
            element={
              <ProtectedRoute>
                <Sale />
              </ProtectedRoute>
            }
          />
          <Route
            path="/listsale"
            element={
              <ProtectedRoute>
                <ListSale />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sales-report"
            element={
              <ProtectedRoute>
                <SalesReport />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </div>
    </SnackbarProvider>
  );
}

export default App; 