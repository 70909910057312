import { useContext } from "react";
import { SnackbarContext } from "../../contexts/SnackbarContext";

const useShowAlert = () => {
  const { showSnackbar } = useContext(SnackbarContext);
  const showAlert = (status, message) => {
    showSnackbar({ severity: status, message: message });
    return true;
  };
  return showAlert;
};

export default useShowAlert;