import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { getAccessStoreToken } from "../../components/utils/CookieUtils";
import ErrorMsg from "../messages/ErrorMessages";
import uRLs from "../utils/BaseUtils";
import useShowAlert from "../utils/AlertUtils";
import checkNetworkStatus from "../reusable/NetworkStatus";
import ViewSalesReport from "./ViewSalesReport";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { CircularProgress } from "@mui/material";
import CurrencyConverter from "@heartinz/currency_subunit_converter";
import DateConverter from "@heartinz/date_and_time_processor";
import { UserContext } from "../../contexts/UserContext";
const accessStoreToken = getAccessStoreToken();

const SalesReport = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaleData, setSaleData] = useState([]);
    const [isSubStoreList, setSubStoreList] = useState([]);
    const [isSelectedStore, setSelectedStore] = useState("");
    const [error, setError] = useState("");
    const [isbtnLoading, setButtonLoading] = useState(false);
    const { user } = useContext(UserContext);
    const showAlert = useShowAlert();

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(formatDate(currentDate));
    const [toDate, setToDate] = useState(formatDate(currentDate));

    const handleSaleReport = async () => {
        setIsLoading(true);
        setButtonLoading(true);
        checkNetworkStatus();
        try {
            if (!isSelectedStore) {
                setError(ErrorMsg.EMPTY_STORE);
                return;
            }
            if (!accessStoreToken) {
                showAlert("warning", ErrorMsg.TOKEN_MISSING);
                return;
            }

            var data = {
                subid: isSelectedStore,
                fromdate: DateConverter.toISO(fromDate, "dd-MM-yyyy", `${user.timezone}`),
                todate: DateConverter.toISO(toDate, "dd-MM-yyyy", `${user.timezone}`),
            }

           
            const response = await axios.post(`${uRLs.API_URL}get-sales-report`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessStoreToken}`,
                },
            });
            setSaleData(response.data.results);
            setError("");
        } catch (error) {
            if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
                showAlert("error", ErrorMsg.NO_INTERNET);
            } else {
                showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
            }
        } finally {
            setIsLoading(false);
            setButtonLoading(false);
        }
    };

    const handleListStore = async (e) => {
        setIsLoading(true);
        checkNetworkStatus();
        try {
            if (!accessStoreToken) {
                showAlert("warning", ErrorMsg.TOKEN_MISSING);
                return;
            }
            const response = await axios.get(`${uRLs.API_URL}list-substore`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessStoreToken}`,
                },
            });
            setSubStoreList(response.data.results);
        } catch (error) {
            if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
                showAlert("error", ErrorMsg.NO_INTERNET);
            } else {
                showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const calculateDate = (baseDate, offsetDays) => {
        const resultDate = new Date(baseDate);
        resultDate.setDate(resultDate.getDate() + offsetDays);
        return resultDate;
    };

    const handleFromDateChange = ([selectedDate]) => {
        const formattedDate = formatDate(selectedDate);
        setFromDate(formattedDate);
        const toDateValue = new Date(toDate.split("-").reverse().join("-"));
        const minToDate = calculateDate(selectedDate, -64);
        const maxToDate = new Date(Math.min(calculateDate(selectedDate, 64), currentDate));
        if (toDateValue < minToDate || toDateValue > maxToDate) {
            setToDate(formattedDate);
        }
    };

    const handleToDateChange = ([selectedDate]) => {
        setToDate(formatDate(selectedDate));
    };

    const toDateMin = calculateDate(new Date(fromDate.split("-").reverse().join("-")), -64); // 64 days back
    const toDateMax = new Date(
        Math.min(
            calculateDate(new Date(fromDate.split("-").reverse().join("-")), 64),
            currentDate
        )
    );

    const downloadExcel = () => {
        const table = document.querySelector(".rtTable");
        if (!table) {
          return;
        }
    
        const tableHTML = table.outerHTML.replace(/ /g, "%20");
        const dataUri = `data:application/vnd.ms-excel;charset=utf-8,${tableHTML}`;
    
        const link = document.createElement("a");
        link.href = dataUri;
        link.download = "Sales_Report.xls";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      ;
    }

    useEffect(() => {
        handleListStore();
    }, []);

    return (
        <div>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Sales Report</div>
                        <div className="ms-auto">
                            <div className="dropdown">
                                <button className="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                    aria-expanded="false"><i className="bx bx-export"></i> Export</button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="javascript:;">
                                        <i className="bx bx-printer"></i> <span className="icon-text">Print</span></a>
                                    </li>
                                    <li><a className="dropdown-item" onClick={downloadExcel}  href="javascript:;">
                                        <i className="bx bx-spreadsheet"></i> <span className="icon-text">Excel</span></a>
                                    </li>
                                    {/* <li><a className="dropdown-item" href="javascript:;">
                                        <i className="bx bx-receipt"></i> <span className="icon-text">PDF</span></a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <label className="form-label w-100">From Date <span className="text-danger"> *</span></label>
                                            <Flatpickr
                                                value={new Date(fromDate.split("-").reverse().join("-"))}
                                                onChange={handleFromDateChange}
                                                options={{
                                                    maxDate: currentDate, 
                                                    dateFormat: "d M Y",
                                                }}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label w-100">To Date <span className="text-danger"> *</span></label>
                                            <Flatpickr
                                                value={new Date(toDate.split("-").reverse().join("-"))}
                                                onChange={handleToDateChange}
                                                options={{
                                                    minDate: toDateMin,
                                                    maxDate: toDateMax, 
                                                    dateFormat: "d M Y", 
                                                }}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label">Sub Store</label>
                                            <select id="subid" className="form-select" onChange={(e) => setSelectedStore(e.target.value)}>
                                                <option value="">Select</option>
                                                {isSubStoreList.map((item, index) => (
                                                    <option key={index} value={item._id}>{item.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {error && <div className="invalid-feedback">{error}</div>}
                                        <div className="col-12">
                                            <div className="d-grid">
                                                {isbtnLoading ? (
                                                    <button className="btn btn-primary pb-0 text-right" disabled>
                                                        <CircularProgress size={22} className="text-white" />
                                                    </button>
                                                ) : (
                                                    <button type="button" className="btn btn-primary" id="salesreportbtn"
                                                        onClick={handleSaleReport}>
                                                        Generate <i className="bx bx-chevron-right"></i>
                                                    </button>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="card p-3">
                                <ViewSalesReport
                                    isData={isSaleData}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesReport;