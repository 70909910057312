import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import checkNetworkStatus from "../reusable/NetworkStatus";
import ErrorMsg from "../messages/ErrorMessages";
import SuccessMsg from "../messages/SuccessMessages";
import uRLs from "../utils/BaseUtils";
import useShowAlert from "../utils/AlertUtils";
import { useNavigate } from "react-router-dom";

const InviteSub = () => {
  const { token } = useParams();
  const showAlert = useShowAlert();
  const [isLoading, setIsLoading] = useState(false); 
  const [isText, setIsText] = useState("Your Invitation Inprogress..."); 
  const navigate = useNavigate();

  const handleInvite = async (token) => {
    checkNetworkStatus();
    setIsLoading(true);
    try {
      if (!token) {
        showAlert("warning", ErrorMsg.TOKEN_MISSING);
        return;
      }
      const response = await axios.get(`${uRLs.API_URL}invite-sub`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      showAlert("success", SuccessMsg.INVITE_SUCCESS);
      const snackbarDisplayTime = 1000;
      setTimeout(() => {
        navigate('/login');
      }, snackbarDisplayTime);
    } catch (error) {
      if (error.message === "NO_INTERNET") {
        showAlert("error", ErrorMsg.NO_INTERNET);
        setIsText(ErrorMsg.NO_INTERNET)
      } else if (error.code === "ERR_NETWORK") {
        showAlert("error", ErrorMsg.SERVER_DOWN);
        setIsText(ErrorMsg.SERVER_DOWN)
      } else if (error.response && error.response.data?.message) {
        showAlert("warning", error.response.data.message);
        setIsText(error.response.data.message)
      } else if (error.code === 'ERR_BAD_REQUEST')  {
        showAlert("warning", ErrorMsg.URL_NOT_FOUND);
        setIsText(ErrorMsg.URL_NOT_FOUND)
      } else {
        showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
        setIsText(ErrorMsg.SERVER_ERROR)
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleInvite(token);
  }, [token]);

  return (
    <div className="wrapper">
      <div className="authentication-forgot d-flex align-items-center justify-content-center">
        <div className="card forgot-box">
          <div className="card-body">
            <div className="p-3 text-center">
              <div className="text-center mb-3">
                <img src="/assets/images/v1/logo-icon.png" width="40" alt="Login" />
              </div>
              <h4 className="mt-3 font-weight-bold">Invitation</h4>
                {isLoading ? (
                  <p className="text-muted">Your invitation is authenticating...</p>
                ) : (
                  <>
                    <p className="text-muted mb-0">{isText}</p>
                    <p>Ask your sharer to invite you again.</p>
                  </>
                )}
              <div className="d-grid gap-2">
                {isLoading ? (
                  <button className="btn btn-primary pb-0 text-right" disabled>
                    <CircularProgress size={22} className="text-white" />
                  </button>
                ) : ("")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteSub;