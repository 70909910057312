import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import uRLs from "../utils/BaseUtils";
import { getAccessStoreToken } from "../../components/utils/CookieUtils";
import CurrencyConverter from "@heartinz/currency_subunit_converter";
import PriceCalculator from "@heartinz/gst_india_product_price_calculator";
import TotalCalculator from "@heartinz/checkout_billing_price_validator";
import ErrorMsg from "../messages/ErrorMessages";
import useShowAlert from "../utils/AlertUtils";
import SuccessMsg from "../messages/SuccessMessages";
import checkNetworkStatus from "../reusable/NetworkStatus";
import ViewSale from "./ViewSale";
import { CircularProgress } from "@mui/material";
const accessStoreToken = getAccessStoreToken();

const Sale = () => {
    const showAlert = useShowAlert();
    const [searchTerm, setSearchTerm] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [cart, setCart] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const suggestionsRef = useRef(null);
    const [isbtnLoading, setButtonLoading] = useState(false);

    const [customerSearchTerm, setCustomerSearchTerm] = useState("");
    const [customerSuggestions, setCustomerSuggestions] = useState([]);
    const [isCustomerLoading, setIsCustomerLoading] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [totalPayable, setTotalPayable] = useState(0);
    const [totalMRP, setTotalMRP] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalSaleprice, setTotalSalePrice] = useState(0);
    const [totalTaxamount, setTotalTaxAmount] = useState(0);
    const [totalBaseprice, setTotalBasePrice] = useState(0);
    const [totalQty, setTotalQuantity] = useState(0);
    const [totalRes, setTotals] = useState(0);

    const [isSaleModalOpen, setSaleModalOpen] = useState(false);
    const [data, setSaleData] = useState(null);
    // const stopEventPropagation = (e) => e.stopPropagation();

    const [cash, setCash] = useState(0);
    const [card, setCard] = useState(0);
    const [upi, setUpi] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [balance, setBalance] = useState(totalPayable);

    const currentDate = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(new Date());

    useEffect(() => {
        const savedCart = localStorage.getItem("cart");
        if (savedCart && savedCart !== "undefined") {
            setCart(JSON.parse(savedCart));
        } else {
            setCart([]);
        }
        calculateTotal();
    }, []);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
        calculateTotal();
    }, [cart]);

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        var cashAmt=CurrencyConverter.toSubunit(cash || 0, 'INR');
        var cardAmt=CurrencyConverter.toSubunit(card || 0, 'INR');
        var upiAmt=CurrencyConverter.toSubunit(upi || 0, 'INR');
        var disAmt=CurrencyConverter.toSubunit(discount || 0, 'INR');
        var onlineAmt=CurrencyConverter.toSubunit(0, 'INR');
        var paylaterAmt=CurrencyConverter.toSubunit(0, 'INR');

        var payObject = {
            "cashamount":cashAmt, "cardamount":cardAmt, "onlineamount":onlineAmt, "discountamount":disAmt, "upiamount":upiAmt, "paylateramount":paylaterAmt
        }
        var totalVal = TotalCalculator.validateCheckout(totalPayable, payObject);
        setTotalPaid(totalVal.totalPaid);
        setBalance(totalVal.balance);
    }, [cash, card, upi, discount, totalPayable]);

    const handleSearch = async (term) => {
        setSearchTerm(term);
        if (term.trim() === "") {
            setSuggestions([]);
            return;
        }

        try {
            setIsLoading(true);
            const data = { searchtxt: term };
            const response = await axios.post(`${uRLs.API_URL}search-product`, data, {
                headers: {
                    Authorization: `Bearer ${accessStoreToken}`,
                },
            });
            setSuggestions(response.data.results || []);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectSuggestion = (product) => {
        setSearchTerm(""); // Set the input value to the selected product name
        addToCart(product); // Add product to the cart
        setSuggestions([]); // Clear suggestions
    };

    const addToCart = (product) => {
        let quantity;
        setCart((prevCart) => {
            const existingProduct = prevCart.find((item) => item._id === product._id);
            if (existingProduct) {
                quantity = parseInt(existingProduct.quantity) + parseInt(1);
                var updatedProduct = PriceCalculator.getAllPriceList(
                    product.mrp,
                    product.saleprice,
                    product.baseprice,
                    product.taxpercentage,
                    quantity,
                    product.isLock
                );
                return prevCart.map((item) =>
                    item._id === product._id
                        ? {
                            ...item,
                            quantity: updatedProduct.quantity,
                            lineGrandTotal: updatedProduct.lineGrandTotal,
                            lineTotalBasePrice: updatedProduct.lineTotalBasePrice,
                            lineTotalDiscount: updatedProduct.lineTotalDiscount,
                            lineTotalMrp: updatedProduct.lineTotalMrp,
                            lineTotalSalePrice: updatedProduct.lineTotalSalePrice,
                            lineTotalTaxAmount: updatedProduct.lineTotalTaxAmount,
                        }
                        : item
                );
            } else {
                quantity = parseInt(1);
                var productItem = PriceCalculator.getAllPriceList(
                    product.mrp,
                    product.saleprice,
                    product.baseprice,
                    product.taxpercentage,
                    quantity,
                    product.isLock
                );
                return [
                    ...prevCart,
                    {
                        ...product,
                        quantity : quantity,
                        lineGrandTotal: productItem.lineGrandTotal,
                        lineTotalBasePrice: productItem.lineTotalBasePrice,
                        lineTotalDiscount: productItem.lineTotalDiscount,
                        lineTotalMrp: productItem.lineTotalMrp,
                        lineTotalSalePrice: productItem.lineTotalSalePrice,
                        lineTotalTaxAmount: productItem.lineTotalTaxAmount,
                    },
                ];
            }
        });
    };

    const updateQuantity = (productId, newQty) => {
        if (!isNaN(newQty) && newQty > 0) {
            setCart((prevCart) =>
                prevCart.map((item) => {
                    if (item._id === productId) {
                        var productItem = PriceCalculator.getAllPriceList(
                            item.mrp,
                            item.saleprice,
                            item.baseprice,
                            item.taxpercentage,
                            parseInt(newQty),
                            item.isLock,
                        );

                        return {
                            ...item,
                            quantity: parseInt(newQty),
                            lineGrandTotal: productItem.lineGrandTotal,
                            lineTotalBasePrice: productItem.lineTotalBasePrice,
                            lineTotalDiscount: productItem.lineTotalDiscount,
                            lineTotalMrp: productItem.lineTotalMrp,
                            lineTotalSalePrice: productItem.lineTotalSalePrice,
                            lineTotalTaxAmount: productItem.lineTotalTaxAmount,
                        };
                    } else {
                        return item;
                    }
                })
            );
        } else if (newQty <= 0) {
            setCart((prevCart) => prevCart.filter((item) => item._id !== productId));
        }
    };

    const calculateTotal = () => {
        // var totalPay = cart.reduce((total, item) => total + item.lineGrandTotal, 0);
        // setTotalPayable(CurrencyConverter.toCurrency(totalPay || 0, "INR"));

        var totalMrPrice = cart.reduce((total, item) => total + item.lineTotalMrp, 0);
        setTotalMRP(CurrencyConverter.toCurrency(totalMrPrice || 0, "INR"));
        
        // var totalDis = cart.reduce((total, item) => total + item.lineTotalDiscount, 0);
        // setTotalDiscount(CurrencyConverter.toCurrency(totalDis || 0, "INR"));

        var totalSale = cart.reduce((total, item) => total + item.lineTotalSalePrice, 0);
        setTotalSalePrice(CurrencyConverter.toCurrency(totalSale || 0, "INR"));

        var totalBase = cart.reduce((total, item) => total + item.lineTotalBasePrice, 0);
        setTotalBasePrice(CurrencyConverter.toCurrency(totalBase || 0, "INR"));

        var totalTax = cart.reduce((total, item) => total + item.lineTotalTaxAmount, 0);
        setTotalTaxAmount(CurrencyConverter.toCurrency(totalTax || 0, "INR"));

        var totalQty = cart.reduce((total, item) => total + item.quantity, 0);
        setTotalQuantity(totalQty);

        var totalRes = PriceCalculator.getGrandTotal(totalMrPrice, totalSale, totalBase, totalTax);
        setTotals(totalRes);

        setTotalPayable(totalRes.grandtotal);
    };

    const handleCustomerSearch = async (term) => {
        setCustomerSearchTerm(term);
        if (term.trim() === "") {
            setCustomerSuggestions([]);
            return;
        }

        try {
            setIsCustomerLoading(true);
            const response = await axios.post(`${uRLs.API_URL}search-customer`, { searchtxt: term }, {
                headers: {
                    Authorization: `Bearer ${accessStoreToken}`,
                },
            });
            setCustomerSuggestions(response.data.results || []);
        } catch (error) {
            console.error(error);
        } finally {
            setIsCustomerLoading(false);
        }
    };

    const handleSelectCustomer = (customer) => {
        setSelectedCustomer(customer);
        setCustomerSearchTerm("");
        setCustomerSuggestions([]);
    };

    const handleOutsideClick = (e) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(e.target)) {
            setSuggestions([]); 
            setCustomerSuggestions([]); 
        }
    };

    const handleInputChange = (setValue) => (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value)) {
            setValue(value);
        } else {
            setValue(0);
        }
    };

    const handleCancel = () => {
        setCart([]);
        localStorage.removeItem("cart");
        setCash(0);
        setCard(0);
        setUpi(0);
        setDiscount(0);
        setTotalPaid(0);
        setBalance(totalPayable);
        setCustomerSearchTerm("");
        setCustomerSuggestions([]);
        setSelectedCustomer(null);
    };

    const handleReset = () => {
        handleCancel();
    };

    const handleClose = () => {
        setSaleModalOpen(false);
        setSaleData(null);
    };

    const handleAdd = async () => {
        const savedCart = localStorage.getItem("cart");

        if (totalPayable === totalPaid) {
            var saleData = {
                "salemode": "INVOICE",
                "customerid": selectedCustomer?._id ?? "",
                "saleitems": Array.isArray(savedCart) ? savedCart : JSON.parse(savedCart),
                "totalqty": totalQty,
                "totalmrp": totalRes.totalmrp,
                "totalsaleprice": totalRes.totalsaleprice,
                "totalbaseprice": totalRes.totalbaseprice,
                "totaldiscount": totalRes.totaldiscount,
                "totaltaxamount": totalRes.totaltaxamount,
                "grandtotal": totalRes.grandtotal,
                "sgstamount": totalRes.sgstamount,
                "cgstamount": totalRes.cgstamount,
                "igstamount": totalRes.igstamount,
                "amountinwords": totalRes.amountinwords,
                "waveoffamount": 0,
                "cashamount": CurrencyConverter.toSubunit(cash || 0, 'INR'),
                "cardamount": CurrencyConverter.toSubunit(card || 0, 'INR'),
                "onlineamount": CurrencyConverter.toSubunit(upi || 0, 'INR'),
                "discount": CurrencyConverter.toSubunit(discount || 0, 'INR'),
                "creditbalance": 0,
                "paidamount": totalPaid,
                "changeamount": 0,
                "waveoffchange": 0,
                "cashchange": 0,
                "upichange": 0,
                "debitbalance": 0
            }
            checkNetworkStatus();
            setButtonLoading(true);
            try {
                var data = saleData;
                var response = await axios.post(`${uRLs.API_URL}create-saleno`, data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessStoreToken}`,
                    },
                });
                data.saleid = response.data.results._id;

                var saleResp = await axios.post(`${uRLs.API_URL}add-sale`, data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessStoreToken}`,
                    },
                });

                showAlert("success", SuccessMsg.SALE_ADDED);
                setSaleModalOpen(true);
                setSaleData(saleResp.data.results);
                handleReset();
            } catch (error) {
                if (error.message === "NO_INTERNET") {
                    showAlert("error", ErrorMsg.NO_INTERNET);
                  } else if (error.code === "ERR_NETWORK") {
                    showAlert("error", ErrorMsg.SERVER_DOWN);
                  } else if (error.response && error.response.data?.message) {
                      if(error.response.data.code === 'NOT_FOUND') {
                        return false;
                      }  
                      showAlert("warning", error.response.data.message);
                  } 
                  else if (error.code === 'ERR_BAD_REQUEST')  {
                    showAlert("warning", ErrorMsg.URL_NOT_FOUND);
                  } else {
                    showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
                  }
            } finally {
                setButtonLoading(false);
            }
        } else {
            showAlert("warning", ErrorMsg.INVALID_PAID_AMOUNT);
        }
    };

    return (
        <>
        <div>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row py-0">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-header px-3 py-3 d-flex flex-wrap justify-content-between align-items-center gap-3">
                                    <div className="mb-0">
                                        <div className="input-group">
                                            <button className="input-group-text">
                                                <i className="bx bx-calendar"></i>
                                            </button>
                                            <input type="text" className="form-control createdon" value={currentDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body mt-3 py-0">
                                    <div className="row g-2">
                                        <div className="col-sm-8" style={{ position: "relative" }}>
                                            <div className="input-group" ref={suggestionsRef}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="search"
                                                    autoComplete="off"
                                                    value={searchTerm}
                                                    onChange={(e) => handleSearch(e.target.value)}
                                                    placeholder="Search products..."
                                                />
                                                <button className="input-group-text">
                                                    <i className="bx bx-search"></i>
                                                </button>
                                            </div>
                                            {searchTerm && suggestions.length === 0 && (
                                                <ul className="suggestions-list">
                                                    <li className="suggestion-item text-muted">No products found</li>
                                                </ul>
                                            )}
                                            {suggestions.length > 0 && (
                                                <ul className="suggestions-list">
                                                    {suggestions.map((product) => (
                                                        <li
                                                            key={product._id}
                                                            onClick={() => handleSelectSuggestion(product)}
                                                            className="suggestion-item"
                                                        >
                                                            {product.title} - <span className="badge badge-primarybadge bg-label-blue rounded-pill">₹{CurrencyConverter.toCurrency(product.saleprice || 0, "INR")}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body px-0">
                                    <div className="table-responsive mt-0">
                                        <table className="table table-bordered align-middle mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="text-center">S.No</th>
                                                    <th>Description</th>
                                                    <th className="text-center">MRP</th>
                                                    <th className="text-center">Discount</th>
                                                    <th className="text-center">Sale Price</th>
                                                    <th className="text-center">Qty</th>
                                                    <th className="text-center">Total</th>
                                                    <th className="text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart.map((item, index) => (
                                                    <tr key={item._id}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-truncate">{item.title}</td>
                                                        <td className="text-center">₹{CurrencyConverter.toCurrency(item.mrp || 0, "INR")}</td>
                                                        <td className="text-center">₹{CurrencyConverter.toCurrency(item.discount || 0, "INR")}</td>
                                                        <td className="text-center">₹{CurrencyConverter.toCurrency(item.saleprice || 0, "INR")}</td>
                                                        <td width={120}>
                                                            <input
                                                                type="text"
                                                                value={item.quantity}
                                                                onFocus={(e) => e.target.select()}
                                                                onChange={(e) =>
                                                                    updateQuantity(item._id, parseInt(e.target.value || 1))
                                                                }
                                                                className="form-control text-center"
                                                                min="1"
                                                            />
                                                        </td>
                                                        <td className="text-center">₹{(CurrencyConverter.toCurrency(item.lineGrandTotal || 0, "INR"))}</td>
                                                        <td className="text-center">
                                                            <div className="order-actions">
                                                                <button
                                                                    className="btn-label-danger shadow-none me-2"
                                                                    onClick={() => updateQuantity(item._id, 0)}
                                                                >
                                                                    <i className="bx bx-trash"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row bg-light border-0 mt-2 text-center align-items-center m-0">
                                        <div className="col p-2">
                                            <p className="mb-0">Total MRP</p>
                                            <h6 className="mb-0 fs-20">{CurrencyConverter.toCurrency(totalRes.totalmrp || 0, "INR")}</h6>
                                        </div>
                                        <div className="col p-2 fs-20">
                                            <i className="bx bx-minus text-muted"></i>
                                        </div>
                                        <div className="col p-2">
                                            <p className="mb-0">Total Discount</p>
                                            <h6 className="mb-0 fs-20">{CurrencyConverter.toCurrency(totalRes.totaldiscount || 0, "INR")}</h6>
                                        </div>
                                        <div className="col p-2 fs-20">
                                            <i className="lni lni-line-double text-muted"></i>
                                        </div>
                                        <div className="col bg-light-purple p-2">
                                            <p className="mb-0 bg-dark-purple">Total Amount</p>
                                            <h6 className="mb-0 bg-dark-purple fs-20">{CurrencyConverter.toCurrency(totalRes.totalsaleprice || 0, "INR")}</h6>
                                        </div>
                                        <div className="col p-2 fs-20">
                                            <i className="lni lni-line-double text-muted"></i>
                                            <i className="lni lni-chevron-right text-muted"></i>
                                        </div>
                                        <div className="col bg-dark-blue p-2">
                                            <p className="mb-0 bg-dark-text">Grand Total <small className="fs-14">(Rounded)</small></p>
                                            <h6 className="mb-0 bg-dark-text fs-20">{CurrencyConverter.toCurrency(totalRes.grandtotal || 0, "INR")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="ms-auto position-relative">
                                        <div className="input-group" ref={suggestionsRef}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="search"
                                                autoComplete="off"
                                                value={customerSearchTerm}
                                                onChange={(e) => handleCustomerSearch(e.target.value)}
                                                placeholder="Search customers..."
                                            />
                                            <button className="input-group-text">
                                                <i className="bx bx-search"></i>
                                            </button>
                                        </div>

                                        {customerSearchTerm && customerSuggestions.length === 0 && (
                                            <ul className="suggestions-list">
                                                <li className="suggestion-item text-muted">No customers found</li>
                                            </ul>
                                        )}

                                        {customerSuggestions.length > 0 && (
                                            <ul className="suggestions-list">
                                                {customerSuggestions.map((customer) => (
                                                    <li
                                                        key={customer._id}
                                                        onClick={() => handleSelectCustomer(customer)}
                                                        className="suggestion-item"
                                                    >
                                                        {customer.name} - <span className="badge badge-primary badge bg-label-blue rounded-pill">{customer.phone}</span>
                                                        {customer.referid && (<>
                                                            <small>-</small> <span className="badge badge-info badge bg-label-secondary rounded-pill">{customer.referid}</span></>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                        <div className="bg-light-white px-3 py-2 radius-10">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="mb-2">Customer info</h6>
                                            </div>
                                            {selectedCustomer ? (
                                                <>
                                                    <p className="mb-1">Name: <span className="fw-600">{selectedCustomer.name}</span></p>
                                                    <p className="mb-1">Phone: <span className="fw-600">{selectedCustomer.phone} </span></p>
                                                    {selectedCustomer.referid && (
                                                        <p className="mb-0">Reference ID: <span className="fw-600">{selectedCustomer.referid}</span></p>
                                                    )}
                                                </>
                                            ) : (
                                                <p className="mb-1 text-muted">No customer found</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="rounded mb-3">
                                        <div className="bg-light  rounded p-2 text-center mb-3">
                                            <h6 className="mb-0 fs-18 text-dark">Total Payable : <span> {CurrencyConverter.toCurrency(totalRes.grandtotal || 0, "INR")} </span></h6>
                                        </div>
                                        <div className="flex-grow-1 row justify-content-between align-items-center g-2">
                                            <div className="col-6">
                                                <h6 className="mb-0">Cash</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    value={cash}
                                                    onFocus={(e) => e.target.select()}
                                                    onChange={handleInputChange(setCash)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <h6 className="mb-0">Card</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    value={card}
                                                    onFocus={(e) => e.target.select()}
                                                    onChange={handleInputChange(setCard)}
                                                />
                                            </div>

                                            <div className="col-6">
                                                <h6 className="mb-0">Online Pay (UPI)</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    value={upi}
                                                    onFocus={(e) => e.target.select()}
                                                    onChange={handleInputChange(setUpi)}
                                                />
                                            </div>

                                            <div className="col-6">
                                                <h6 className="mb-0">Discount</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    value={discount}
                                                    onFocus={(e) => e.target.select()}
                                                    onChange={handleInputChange(setDiscount)}
                                                />
                                            </div>

                                            <div className="col-6">
                                                <h6 className="mb-0 text-dark fw-500">Total Paid</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <input
                                                    type="text"
                                                    className="form-control text-dark"
                                                    value={CurrencyConverter.toCurrency(totalPaid || 0, 'INR')}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-6">
                                            <h6 className={`mb-0 
                                                ${  
                                                    balance === 0  ? 'text-grey' : balance > 0  ? 'text-danger' : 'text-success'
                                                }`} >
                                                {balance === 0 ? 'Balance' : balance > 0 ? 'Balance' : 'Change'}
                                            </h6>
                                            </div>
                                            <div className="col-6 text-end">
                                            <input
                                                type="text"
                                                className={`form-control ${ balance === 0  ? 'bg-box-grey' : balance > 0 ? 'bg-box-danger' : 'bg-box-success' }`}
                                                value={CurrencyConverter.toCurrency(balance || 0, 'INR')}
                                                disabled
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-block">
                                        <button type="button" className="btn shadow-none btn-outline-secondary text-left" onClick={handleCancel}><i className="bx bx-x"></i> Cancel</button>
                                        {isbtnLoading ? (
                                            <button className="btn btn-primary pb-0 text-right" disabled>
                                            <CircularProgress size={22} className="text-white" />
                                            </button>
                                        ) : (
                                            <button type="button" className="btn btn-primary text-right w-48"
                                            onClick={handleAdd}>
                                            <i className="bx bx-check"></i> Save
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        { isSaleModalOpen && (
           <ViewSale
            data={data}
            onClose={handleClose}
           />
        )}
        </>
    );
}

export default Sale;