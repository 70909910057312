const SuccessMessages = {

    // Product Success
    CUSTOMER_ADDED: "Customer added successfully.",
    CUSTOMER_UPDATED: "Customer updated successfully.",
    CUSTOMER_DELETED: "Customer deleted successfully.",

    // General Success
    OPERATION_SUCCESS: "The operation was successful.",
    DATA_FETCH_SUCCESS: "Data fetched successfully.",
    DATA_SAVE_SUCCESS: "Data saved successfully.",
    CONNECTION_RESTORED: "You are back online. Connection restored.",

    // Authentication Success
    LOGIN_SUCCESS: "Login successful. Welcome back!",
    LOGOUT_SUCCESS: "You have successfully logged out.",
    PASSWORD_RESET_SUCCESS: "Password reset successful. You can now log in with the new password.",

    // Email Success
    EMAIL_SENT: "Email has been sent successfully.",
    OTP_SENT: "OTP sent successfully. Please check your email.",
    EMAIL_VERIFIED: "Your email has been verified successfully.",
    INVITE_SUCCESS: "Your invitation accepted and account created successfully.",

    // OTP Success
    OTP_VERIFIED: "OTP verified successfully.",

    // File Upload Success
    FILE_UPLOADED: "File uploaded successfully.",

    // User Account Success
    ACCOUNT_CREATED: "Your account has been created successfully.",
    ACCOUNT_UPDATED: "Your account details have been updated successfully.",
    ACCOUNT_DELETED: "Your account has been deleted successfully.",

    // Product Success
    PRODUCT_ADDED: "Product added successfully.",
    PRODUCT_UPDATED: "Product updated successfully.",
    PRODUCT_DELETED: "Product deleted successfully.",
    PRODUCT_ADDED_TO_WISHLIST: "Product added to your wishlist.",
    PRODUCT_REMOVED_FROM_WISHLIST: "Product removed from your wishlist.",

    // Cart Success
    ITEM_ADDED_TO_CART: "Item added to your cart.",
    ITEM_REMOVED_FROM_CART: "Item removed from your cart.",
    CART_UPDATED: "Your cart has been updated.",

    // Order Success
    ORDER_PLACED: "Your order has been placed successfully.",
    ORDER_CANCELLED: "Your order has been cancelled successfully.",
    ORDER_RETURN_INITIATED: "Return request initiated successfully.",
    ORDER_DELIVERED: "Your order has been delivered successfully.",

    // Payment Success
    PAYMENT_SUCCESS: "Payment completed successfully.",
    REFUND_INITIATED: "Your refund has been initiated successfully.",

    // Review Success
    REVIEW_SUBMITTED: "Your review has been submitted successfully.",
    REVIEW_UPDATED: "Your review has been updated successfully.",
    REVIEW_DELETED: "Your review has been deleted successfully.",

    // Shipping Success
    SHIPPING_ADDRESS_ADDED: "Shipping address added successfully.",
    SHIPPING_ADDRESS_UPDATED: "Shipping address updated successfully.",
    SHIPPING_ADDRESS_DELETED: "Shipping address deleted successfully.",

    // Discount and Offers Success
    COUPON_APPLIED: "Coupon applied successfully.",
    COUPON_REMOVED: "Coupon removed successfully.",
    DISCOUNT_APPLIED: "Discount applied successfully to your order.",

    // Notification Success
    NOTIFICATION_SUBSCRIBED: "You have successfully subscribed to notifications.",
    NOTIFICATION_UNSUBSCRIBED: "You have successfully unsubscribed from notifications.",

    // Custom Success
    CUSTOM_SUCCESS: "Operation completed successfully.",

    FORM_SUBMITTED: "Form submitted successfully.",

    SALE_ADDED: "Sale added successfully.",
};

export default SuccessMessages;