import React, { useRef } from "react";
import { CircularProgress } from "@mui/material";

const EmailStep = ({ email, setEmail, onSubmit, loading, error }) => {
    const emailInputRef = useRef(null);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        emailInputRef.current.focus();
        onSubmit(event);
    };

    return (
        <div>
            <h4 className="text-center mt-5 font-weight-bold">Welcome to Nego Bill Portal</h4>
            <p className="text-center mb-4">Continue with your email ID to proceed.</p>
            <form className="row g-3" onSubmit={handleFormSubmit}>
                <div className="col-12">
                    <label htmlFor="email" className="form-label">Email 
                        <span className="text-danger"> *</span>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        ref={emailInputRef}
                    />
                    {error && <div className="invalid-feedback">{error}</div>}
                </div>
                <div className="col-12">
                    <div className="d-grid">
                        {loading ? (
                            <button className="btn btn-primary pb-0" disabled>
                                <CircularProgress size={22} className="text-white" />
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary">
                                Send OTP
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EmailStep;