import axios from "axios";
import { setAuthCookie } from "./CookieUtils";
import ErrorMsg from "../messages/ErrorMessages";
import checkNetworkStatus from "../reusable/NetworkStatus";
import uRLs from "../utils/BaseUtils";

export const authStore = async (accessToken, showAlert) => {
  checkNetworkStatus();
  try {
    if (!accessToken) {
      showAlert("error", ErrorMsg.TOKEN_MISSING);
    }
    const response = await axios.get(`${uRLs.API_URL}auth-store`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
    });
    setAuthCookie({
      accessStoreToken: response.data.results.accessToken,
      refreshStoreToken: response.data.results.refreshToken,
    });
    return true;
  } catch (error) {
    if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
      showAlert("error", ErrorMsg.NO_INTERNET);
    } else if (error.response && error.response.data?.message) {
      showAlert("warning", error.response.data.message);
    } else {
      showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
    }
  }
};