import React from 'react';
// import StatCard from './StatCard';

const Dashboard = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          {/* <div className="row row-cols-1 row-cols-lg-4">
            <StatCard
              title="Sessions"
              value="32,842"
              percentage="+40%"
              description="Analytics for last week"
              icon="bx bx-refresh"
              iconClass="bg-gradient-cosmic"
            />
            <StatCard
              title="Users"
              value="16,352"
              percentage="+22%"
              description="Analytics for last week"
              icon="bx bx-group"
              iconClass="bg-gradient-burning"
            />
            <StatCard
              title="Time on Site"
              value="34m 14s"
              percentage="+55%"
              description="Analytics for last week"
              icon="bx bx-time"
              iconClass="bg-gradient-lush"
            />
            <StatCard
              title="Goal Completions"
              value="1,94,2335"
              description="Analytics for last month"
              icon="bx bxs-cube"
              iconClass="bg-gradient-kyoto"
            />
          </div> */}
        </div>
      </div>
</div>
);
};

export default Dashboard;