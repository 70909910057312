import React from "react";

const DeleteModal = ({ onClose, onConfirm, Name }) => {
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <div class="modal-backdrop show"></div>
      <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onClose}
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-scrollable"
          onClick={stopPropagation}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className="modal-content border-0">
            <div className="modal-header" style={{ backgroundColor: '#ff0000e0' }}>
              <h5 className="modal-title" style={{ color: 'white' }}>Confirm Deletion</h5>
              <button
                type="button"
                onClick={onClose}
                className="btn-close"
                style={{ color: "white" }}
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to delete this {Name}? This action cannot
                be undone.
              </p>
            </div>
            <div className="modal-footer d-block">
            <button
                type="button"
                onClick={onClose}
                className="btn btn-outline-secondary text-left"
              >
                <i className="bx bx-x" style={{ fontSize: '20px' }}></i> Cancel
              </button>
              <button
                type="button"
                onClick={onConfirm}
                className="btn btn-danger text-right"
              >
                <i className="bx bx-trash" style={{ fontSize: '20px' }}></i> Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;