import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const FullPageLoader = ({ isLoading }) => (
  <Backdrop
    sx={{
      color: "#fff",
      zIndex: (theme) => theme.zIndex.drawer + 1,
      backgroundColor: "rgba(0, 0, 0, 0.1)", 
    }}
    open={isLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default FullPageLoader;