import React from "react";
import { Skeleton } from "@mui/material";
import CurrencyConverter from "@heartinz/currency_subunit_converter";
import DateConverter from "@heartinz/date_and_time_processor";

const ViewSalesReport = ({ isData, isLoading }) => {
    return (
        <>
            <div className="table-responsive rtTable">

                <table class="table table-sm table-bordered text-center">
                    <tbody>
                        <tr>
                            <td className="fw-600">SALES REPORT</td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex rtable">
                    <div className="w-50">
                        <table class="table table-sm table-bordered">
                            <tbody>
                                <tr>
                                    <td className="w-25">Name</td>
                                    <td>
                                        {isLoading ? (
                                            <Skeleton variant="text" />
                                        ) : (
                                            isData?.reportTo?.title || ""
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">From Date</td>
                                    <td>
                                        {isLoading ? (
                                            <Skeleton variant="text" />
                                        ) : (
                                            isData?.fromDate ? DateConverter.ISOToDisplayFormat(isData.fromDate).date : ""
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">To Date</td>
                                    <td>
                                        {isLoading ? (
                                            <Skeleton variant="text" />
                                        ) : (
                                            isData?.toDate
                                                ? DateConverter.ISOToDisplayFormat(isData.toDate).date
                                                : ""
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">No of Sales</td>
                                    <td>
                                        {isLoading ? (
                                            <Skeleton variant="text" />
                                        ) : (
                                            `${isData.totalCount || 0}`
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="w-10"></div>

                    <div className="w-50">
                        <table class="table table-sm table-bordered mb-0 text-center">
                            <tbody>
                                <tr>
                                    <td className="w-25">Base Total</td>
                                    <td className="w-25">GST Total</td>
                                    <td className="w-25">Grand Total</td>
                                </tr>
                                <tr>
                                    <td className="fw-600">
                                        {isLoading ? (
                                            <Skeleton variant="text" />
                                        ) : (
                                            `₹${CurrencyConverter.toCurrency(Number(isData.totalBase) || 0, "INR")}`
                                        )}
                                    </td>
                                    <td className="fw-600">
                                        {isLoading ? (
                                            <Skeleton variant="text" />
                                        ) : (
                                            `₹${CurrencyConverter.toCurrency(Number(isData.totalTax) || 0, "INR")}`
                                        )}
                                    </td>
                                    <td className="fw-600">
                                        {isLoading ? (
                                            <Skeleton variant="text" />
                                        ) : (
                                            `₹${CurrencyConverter.toCurrency(Number(isData.totalGrand) || 0, "INR")}`
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <table className="table table-sm table-bordered mb-3 rtable">
                    <tbody>
                        <tr>
                            <td className="text-center">S No</td>
                            <td className="text-center">No</td>
                            <td className="text-center">Date</td>
                            <td className="text-center">MRP</td>
                            <td className="text-center">Discount</td>
                            <td className="text-center">Sale Price</td>
                            <td className="text-center">GST</td>
                            <td className="text-center">Amount</td>
                        </tr>
                    </tbody>
                    <tbody>
                        {isLoading ? (
                            [...Array(10)].map((_, index) => (
                                <tr key={index}>
                                    <td className="text-center">
                                        <Skeleton variant="text" />
                                    </td>
                                    <td className="text-center">
                                        <Skeleton variant="text" />
                                    </td>
                                    <td className="text-center">
                                        <Skeleton variant="text" />
                                    </td>
                                    <td className="text-center">
                                        <Skeleton variant="center" />
                                    </td>
                                    <td className="text-center">
                                        <Skeleton variant="text" />
                                    </td>
                                    <td className="text-center">
                                        <Skeleton variant="text" />
                                    </td>
                                    <td className="text-center">
                                        <Skeleton variant="text" />
                                    </td>
                                    <td className="text-center">
                                        <Skeleton variant="text" />
                                    </td>
                                </tr>
                            ))
                        ) : Array.isArray(isData.saleList) &&
                            isData.saleList.length > 0 ? (
                            isData.saleList.map((item, index) => (
                                <tr key={item.id || index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">
                                        {item.saleno}
                                    </td>
                                    <td className="text-center">
                                        {DateConverter.ISOToDisplayFormat(item.saledate).date}
                                    </td>
                                    <td className="text-center">
                                        ₹{CurrencyConverter.toCurrency(item.totalmrp || 0, "INR")}
                                    </td>
                                    <td className="text-center">
                                        ₹{CurrencyConverter.toCurrency(item.totaldiscount || 0, "INR")}
                                    </td>
                                    <td className="text-center">
                                        ₹{CurrencyConverter.toCurrency(item.totalsaleprice || 0, "INR")}
                                    </td>
                                    <td className="text-center">
                                        ₹{CurrencyConverter.toCurrency(item.totaltaxamount || 0, "INR")}
                                    </td>
                                    <td className="text-center">
                                        ₹{CurrencyConverter.toCurrency(Number(item.grandtotal) || 0, 'INR')}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center p-2">
                                    <small className="text-muted">No sale found in the store. add some customers to get started.</small>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td colSpan="3" className="text-center"></td>
                            <td className="text-center fw-600">
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(isData.totalMrp || 0, "INR")}`
                                )}
                            </td>
                            <td className="text-center fw-600">
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(isData.totalDiscount || 0, "INR")}`
                                )}
                            </td>
                            <td className="text-center fw-600">
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(isData.totalSale || 0, "INR")}`
                                )}
                            </td>
                            <td className="text-center fw-600">
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(isData.totalTax || 0, "INR")}`
                                )}
                            </td>
                            <td className="text-center fw-600">
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(isData.totalGrand || 0, "INR")}`
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>


                <table class="table table-sm table-bordered mb-0 rtable w-30">
                    <tbody>
                        <tr>
                            <td className="w-25">Cash</td>
                            <td>
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(Number(isData.totalCash) || 0, "INR")}`
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="w-25">Card</td>
                            <td>
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(Number(isData.totalCard) || 0, "INR")}`
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="w-25">Online Pay (UPI)</td>
                            <td>
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(Number(isData.totalOnline) || 0, "INR")}`
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="w-25">Discount</td>
                            <td>
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    `₹${CurrencyConverter.toCurrency(Number(isData.totalDiscountAmt) || 0, "INR")}`
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </>
    )
};

export default ViewSalesReport;