import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import UserProvider from "./contexts/UserContext";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <AuthProvider> 
      <UserProvider>
        <App />
      </UserProvider>
    </AuthProvider>
  </Router>
);
