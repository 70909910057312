import axios from "axios";
import React, { useState, useEffect } from "react";
import checkNetworkStatus from "../reusable/NetworkStatus";
import { getAccessStoreToken } from "../../components/utils/CookieUtils";
import ErrorMsg from "../messages/ErrorMessages";
import ListRecentProduct from "./ListRecentProduct";
import AddProduct from "./AddProduct";
import uRLs from "../utils/BaseUtils";
import useShowAlert from "../utils/AlertUtils";
const accessStoreToken = getAccessStoreToken();

const Product = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProductData, setProductData] = useState([]);
  const showAlert = useShowAlert();

  const listRecentProduct = async () => {
    checkNetworkStatus();
    try {
      if (!accessStoreToken) {
        showAlert("warning", ErrorMsg.TOKEN_MISSING);
        return;
      }
      const response = await axios.get(`${uRLs.API_URL}recent-product`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessStoreToken}`,
        },
      });
      setProductData(response.data.results);
    } catch (error) {
        if (error.message === "NO_INTERNET") {
          showAlert("error", ErrorMsg.NO_INTERNET);
        } else if (error.code === "ERR_NETWORK") {
          showAlert("error", ErrorMsg.SERVER_DOWN);
        } else if (error.response && error.response.data?.message) {
            if(!error.response.data.code === 'NOT_FOUND') {
              showAlert("warning", error.response.data.message);
            }
        } 
        else if (error.code === 'ERR_BAD_REQUEST')  {
          showAlert("warning", ErrorMsg.URL_NOT_FOUND);
        } else {
          showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
        }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listRecentProduct();
  }, [accessStoreToken]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row">
            <div className="col-lg-5">
              <h6 className="card-title ">Add Product</h6>
              <AddProduct listRecentProduct={listRecentProduct} />
            </div>
            <div className="col-lg-7">
              <h6 className="card-title ">Recently Added Products</h6>
              <div className="card radius-10 mb-0 mt-3">
                <div className="card-body px-0">
                  <ListRecentProduct isData={isProductData} isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Product;