import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAccessStoreToken } from "../../components/utils/CookieUtils";
import ErrorMsg from "../messages/ErrorMessages";
import uRLs from "../utils/BaseUtils";
import useShowAlert from "../utils/AlertUtils";
import { Skeleton } from "@mui/material";
import checkNetworkStatus from "../reusable/NetworkStatus";
import CurrencyConverter from "@heartinz/currency_subunit_converter";
import ViewSale from "./ViewSale";
import DateConverter from "@heartinz/date_and_time_processor";
const accessStoreToken = getAccessStoreToken();

const ListSale = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaleData, setSaleData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSaleModalOpen, setSaleModalOpen] = useState(false);
  const [isData, setSaleViewData] = useState(false);

  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-label-green";
      case "inactive":
        return "bg-label-danger";
      default:
        return "bg-label-secondary";
    }
  };

  const showAlert = useShowAlert();

  const handleList = async () => {
    setIsLoading(true);
    checkNetworkStatus();
    try {
      if (!accessStoreToken) {
        showAlert("warning", ErrorMsg.TOKEN_MISSING);
        return;
      }
      const response = await axios.get(`${uRLs.API_URL}list-sale`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });
      setSaleData(response.data.results);
      setFilteredData(response.data.results);
    } catch (error) {
      if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
        showAlert("error", ErrorMsg.NO_INTERNET);
      } else {
        showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();
    setSearchQuery(query);

    if (!query) {
      setFilteredData(isSaleData); // If the search query is empty, show all data
      return;
    }

    const filtered = isSaleData.filter((sale) => {
      const saleno = String(sale.saleno || "").toLowerCase();
      const saledate = String(new Date(sale.saledate).toLocaleDateString('en-GB') || "").toLowerCase();
      const name = String(sale.saleto?.name || "").toLowerCase();
      const phone = String(sale.saleto?.phone || "").toLowerCase();

      return (
        saleno.includes(query) ||
        saledate.includes(query) ||
        name.includes(query) ||
        phone.includes(query)
      );
    });

    setFilteredData(filtered);
  };

  const handleView = (item) => {
    setSaleModalOpen(true);
    setSaleViewData(item);
  };

  const handleClose = () => {
    setSaleModalOpen(false);
    setSaleViewData(null);
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="page-content">
            <div className="page-breadcrumb d-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">List Sale</div>
              {/* <div className="ms-auto">
                <button type="button" className="btn btn-primary mt-2 mt-lg-0">
                  <i className="ng-ng-Sale-add-icon"></i> Add Sale
                </button>
              </div> */}
            </div>

            <div className="row">
              <div className="col">
                <div className="card radius-10 mb-0">
                  <div className="card-body px-0">
                    <div className="row px-3">
                      <div className="col-lg-5 me-auto">
                        <div className="ms-auto position-relative">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search sale by saleno, saledate, customername, phone, etc..."
                              value={searchQuery}
                              onChange={handleSearch}
                            />
                            <button className="input-group-text">
                              <i className="ng-ng-search-icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive mt-3">
                      <table className="table align-middle mb-0">
                        <thead className="table-light text-center">
                          <tr>
                            <th>S.No</th>
                            <th>Sale Mode</th>
                            <th>Sale No</th>
                            <th>Sale Date</th>
                            <th>Sale To</th>
                            <th>Sale Items</th>
                            <th>Grand Total</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            [...Array(9)].map((_, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  <Skeleton variant="text" width={200} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={200} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                              </tr>
                            ))
                          ) : filteredData.length > 0 ? (
                            filteredData.map((item, index) => (
                              <tr key={item.id || index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">
                                  <span className="badge rounded-pill bg-label-deep-purple">{item.salemode}</span>
                                </td>
                                <td className="text-center">
                                  <span className="badge rounded-pill bg-label-primary">{item.saleno}</span>
                                </td>
                                <td className="text-center">
                                  <span className="text-heading fw-medium text-truncate">
                                      {DateConverter.ISOToDisplayFormat(item.saledate).date}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div className="d-flex flex-column">
                                    <span className="text-nowrap text-heading fw-medium">
                                      {item.saleto ? `${item.saleto.name}` : ''}
                                    </span>
                                    <span className="text-nowrap">
                                      <small>{item.saleto ? `${item.saleto.phone}` : ''}</small>
                                    </span>
                                  </div>
                                </td>
                                <td className="text-center">
                                  {item.saleitems.length} Item(s)
                                </td>
                                <td className="text-center">
                                  <span className="text-heading fw-medium text-truncate">
                                    ₹{CurrencyConverter.toCurrency(Number(item.grandtotal) || 0, 'INR')}
                                  </span>
                                </td>
                                <td className="text-center">
                                  {item.status && (
                                    <span
                                      className={`badge rounded-pill ${getColor(item.status)}`} >
                                      {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <div className="order-actions">
                                    <button
                                      type="button"
                                      className="btn-label-primary shadow-none me-2"
                                      onClick={() => handleView(item)}>
                                      <i className="bx bx-show"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="9" className="text-center p-2">
                                <span className="text-muted">No sale found in the store. Add some sale to get started.</span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isSaleModalOpen && (
        <ViewSale
          data={isData}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default ListSale;