import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

function SideMenu({ toggleSideMenu }) {
  const [expandedMenu, setExpandedMenu] = useState(null);
  const { user } = useContext(UserContext);  // User context to manage user data

  // Toggle the sub-menu
  const handleToggleMenu = (menuId) => {
    setExpandedMenu((prevMenu) => (prevMenu === menuId ? null : menuId));
  };

  // Close the side menu when a link is clicked
  const handleLinkClick = () => {
    toggleSideMenu();
  };

  // Safe check for user context to avoid null/undefined errors
  const userType = user?.stype || ""; // Default to empty string if user is null/undefined

  return (
    <div className="wrapper toggled">
      <div className="overlay toggle-icon bc" onClick={toggleSideMenu}></div>
      <div>
        <div className="sidebar-wrapper" data-simplebar="true">
          <div className="sidebar-header">
            <div>
              <img
                src="/assets/images/v1/logo-dark.png"
                className="logo-icon"
                alt="logo icon"
              />
            </div>
            <div className="toggle-icon ms-auto" onClick={toggleSideMenu}>
              <i className="bx bx-arrow-back"></i>
            </div>
          </div>

          <ul className="metismenu" id="menu">
            {/* Dashboard Link */}
            <li>
              <Link to="/dashboard" onClick={handleLinkClick}>
                <div className="parent-icon" style={{ fontSize: "18px" }}>
                  <i className="ng-ng-home-icon"></i>
                </div>
                <div className="menu-title">Dashboard</div>
              </Link>
            </li>


            <li>
              <Link to="/customer" onClick={handleLinkClick}>
                <div className="parent-icon" style={{ fontSize: "18px" }}>
                  <i className="ng-ng-customers-icon"></i>
                </div>
                <div className="menu-title">Customers</div>
              </Link>
            </li>

            {userType === "MAIN" && (
              <li>
                <div onClick={() => handleToggleMenu("product")}>
                  <Link
                    to="#"
                    className={`has-arrow ${expandedMenu === "product" ? "mm-active" : ""}`}
                    aria-expanded={expandedMenu === "product"}
                  >
                    <div className="parent-icon" style={{ fontSize: "18px" }}>
                      <i className="ng-ng-product-icon"></i>
                    </div>
                    <div className="menu-title">Products</div>
                  </Link>
                </div>
                {expandedMenu === "product" && (
                  <ul>
                    <li>
                      <Link to="/product" onClick={handleLinkClick}>
                        <i className="bx bx-radio-circle"></i>Add Product
                      </Link>
                    </li>
                    <li>
                      <Link to="/listproduct" onClick={handleLinkClick}>
                        <i className="bx bx-radio-circle"></i>List Product
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}

            <li>
              <div onClick={() => handleToggleMenu("sales")}>
                <Link
                  to="#"
                  className={`has-arrow ${expandedMenu === "sales" ? "mm-active" : ""}`}
                  aria-expanded={expandedMenu === "sales"}
                >
                  <div className="parent-icon">
                    <i className="ng-ng-negobill_icon"></i>
                  </div>
                  <div className="menu-title">Sales</div>
                </Link>
              </div>
              {expandedMenu === "sales" && (
                <ul>
                  <li>
                    <Link to="/sales" onClick={handleLinkClick}>
                      <i className="bx bx-radio-circle"></i>Add Sale
                    </Link>
                  </li>
                  <li>
                    <Link to="/listsale" onClick={handleLinkClick}>
                      <i className="bx bx-radio-circle"></i>List Sale
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {userType === "MAIN" && (
              <li>
                <div onClick={() => handleToggleMenu("report")}>
                  <Link
                    to="#"
                    className={`has-arrow ${expandedMenu === "report" ? "mm-active" : ""}`}
                    aria-expanded={expandedMenu === "report"}
                  >
                    <div className="parent-icon" style={{ fontSize: "18px" }}>
                      <i className="bx bx-line-chart"></i>
                    </div>
                    <div className="menu-title">Reports</div>
                  </Link>
                </div>
                {expandedMenu === "report" && (
                  <ul>
                    <li>
                      <Link to="/sales-report" onClick={handleLinkClick}>
                        <i className="bx bx-radio-circle"></i>Sale Report
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}

            {/* {userType === "SUB" && (
               <li>
               <Link to="/hub" onClick={handleLinkClick}>
                 <div className="parent-icon" style={{ fontSize: "18px" }}>
                   <i className="ng-ng-customers-icon"></i>
                 </div>
                 <div className="menu-title">Manage HUB</div>
               </Link>
             </li>
            )} */}

          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;