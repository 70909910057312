import Cookies from 'js-cookie';

export const setAuthCookie = (data) => {
  const existingData = Cookies.get('NgBl-Auth-Data');
  const parsedData = existingData ? JSON.parse(existingData) : {};
  const updatedData = { ...parsedData, ...data };
  Cookies.set('NgBl-Auth-Data', JSON.stringify(updatedData), {
    secure: true,
    sameSite: 'Strict',
    path: '/',
    expires: 300,
  });
  return;
};

export const resetAuthCookie = () => {
  Cookies.remove('NgBl-Auth-Data');
  return null;
};

export const getVerifyToken = () => {
  const authData = Cookies.get('NgBl-Auth-Data');
  if (authData) {
    const parsedData = JSON.parse(authData);
    return parsedData.verifyToken || null;
  }
  return null;
};

export const getAccessToken = () => {
  const authData = Cookies.get('NgBl-Auth-Data');
  if (authData) {
    const parsedData = JSON.parse(authData);
    return parsedData.accessToken || null;
  }
  return null;
};

export const getAccessStoreToken = () => {
  const authData = Cookies.get('NgBl-Auth-Data');
  if (authData) {
    const parsedData = JSON.parse(authData);
    return parsedData.accessStoreToken || null;
  }
  return null;
};

export const getRefreshStoreToken = () => {
  const authData = Cookies.get('NgBl-Auth-Data');
  if (authData) {
    const parsedData = JSON.parse(authData);
    return parsedData.refreshStoreToken || null;
  }
  return null;
};


