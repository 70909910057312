import React from 'react';
import CurrencyConverter from "@heartinz/currency_subunit_converter";
import DateConverter from "@heartinz/date_and_time_processor";

const ViewSale = ({ data, onClose }) => {
   const handleClose = () => {
      onClose();
   };
   
   const printSale = () => {
      const printContents = document.getElementById("element-to-print").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
   };

   return (
      <div>
         <div className="modal-backdrop show"></div>
         <div className="modal show" id="CustomerModal" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-scrollable modal-lg">
               <div className="modal-content">
                  <div className="modal-header">
                     <h6 className="modal-title">{data.salemode}</h6>
                     <button type="button" onClick={handleClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body" id="downloadable-content'">
                     <div className="tm_container" id="element-to-print">
                        <div className="tm_invoice_wrap">
                           <div className="tm_invoice tm_style1 tm_type1">
                              <div className="tm_invoice_in">
                                 <table width="100%" className="pdfcontent">
                                    <tbody>
                                       <tr>
                                          <td width="50%" style={{ textAlign: "left" }}>
                                             <div className="tm_logo">

                                             </div>
                                          </td>
                                          <td width="50%" style={{ textAlign: "right" }}>
                                             <div className="tm_primary_color tm_f50 tm_text_uppercase">
                                                {data.salemode}
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                                 <div className="tm_invoice_info tm_mb20">
                                    <div className="tm_invoice_seperator tm_gray_bg"></div>
                                    <div className="tm_invoice_info_list">
                                       <p className="tm_invoice_number tm_m0 mb-0 mt-3">
                                          <b className="tm_primary_color">Invoice No: </b> {data.saleno}
                                       </p>
                                       <p className="tm_invoice_date tm_m0  mt-3">
                                          <b className="tm_primary_color"> Date: </b> {DateConverter.ISOToDisplayFormat(data.saledate).date}
                                       </p>
                                    </div>
                                 </div>
                                 <table width="100%" className="pdfcontent">
                                    <tbody>
                                       <tr>
                                          <td width="45%">
                                             <p style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600", marginBottom: "0px", }}>
                                                Invoice From:
                                             </p>
                                             <p style={{ fontSize: "12px", fontWeight: "600", marginBottom: "0px", }}>
                                                {data.salefrom?.title || ""}
                                             </p>
                                             <div style={{ fontSize: "12px", lineHeight: "18px", marginBottom: "0px", }}>
                                                <p className="mb-0">
                                                   {data.salefrom?.addressline1 || ""} {data.salefrom?.addressline2 || ""} {data.salefrom?.state || ""} {data.salefrom?.district || ""} {data.salefrom?.country || ""} - {data.salefrom?.pincode || ""}
                                                </p>
                                                <p className="mb-0"> Phone: +91 {data.salefrom?.phone || ""}</p>
                                                <p className="mb-0">
                                                   Email: {data.salefrom?.email || ""}
                                                </p>
                                                <p className="mb-0">
                                                   <span style={{ fontWeight: 600 }}>GST : </span> {data.salefrom?.gstnumber || ""}
                                                </p>
                                                <p className=" mb-0">
                                                   <span style={{ fontWeight: 600 }}>PAN : </span> {data.salefrom?.pannumber || ""}
                                                </p>
                                                <p className=" mb-0">
                                                   <span style={{ fontWeight: 600 }}>
                                                      State : {data.salefrom?.state || ""}
                                                   </span> [ State Code :{data.salefrom?.statecode || ""} ]
                                                </p>
                                             </div>
                                          </td>
                                          <td width="10%"></td>
                                          <td width="45%">
                                             <p style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600", marginBottom: "0px", }}>
                                                Invoice To:
                                             </p>
                                             <p style={{ fontSize: "12px", fontWeight: "600", marginBottom: "0px", }}>
                                                {data.saleto?.name || ""}
                                             </p>
                                             <div style={{ fontSize: "12px", lineHeight: "18px", marginBottom: "0px", }}>
                                                <p className="mb-0">
                                                   {data.saleto?.addressline1 || ""} {data.saleto?.addressline2 || ""} {data.saleto?.state || ""} {data.saleto?.district || ""} {data.saleto?.country || ""} - {data.saleto?.pincode || ""}
                                                </p>
                                                <p className="mb-0"> Phone: +91 {data.saleto?.phone || ""}</p>
                                                <p className="mb-0"> Email: {data.saleto?.email || ""}</p>
                                                <p className="mb-0">
                                                   <span style={{ fontWeight: 600 }}>GST : </span> {data.saleto?.gstnumber || ""}
                                                </p>
                                                <p className=" mb-0">
                                                   <span style={{ fontWeight: 600 }}>
                                                      State : {data.saleto?.state || ""}
                                                   </span> [ State Code :{data.saleto?.statecode || ""} ]
                                                </p>
                                                {data.saleto?.referid && (
                                                   <p className="mb-0"> <span style={{ fontWeight: 600 }}> Reference ID : </span> 
                                                      <>
                                                         {data.saleto?.referid}
                                                      </>
                                                   </p>
                                                )}
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                                 <table className="items " width="100%" style={{ marginTop: "6px", borderCollapse: "collapse", }} cellPadding="4">
                                    <thead>
                                       <tr>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             S.No
                                          </th>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             Description of Products
                                          </th>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             Rate
                                          </th>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             Qty
                                          </th>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             Gross Amount
                                          </th>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             Discounts
                                          </th>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             Taxable Value
                                          </th>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             GST
                                          </th>
                                          <th style={{ textAlign: "center", background: "#f3f3f3", border: "0.5mm solid #e0e0e0", }}>
                                             Total
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {data.saleitems.map((product, index) => (
                                          <tr key={index}>
                                             <td style={{ textAlign: "center", fontSize: "12px" }}>{index + 1}</td>
                                             <td style={{ textAlign: "left", fontSize: "12px" }}>
                                                <div>{product.title}</div>
                                                {product.taxpercentage && (
                                                   <div><b>GST:</b> {product.taxpercentage}%</div>
                                                )} {product.hsnsac && (
                                                   <div><b>HSN/SAC:</b> {product.hsnsac}</div>
                                                )}
                                             </td>
                                             <td style={{ textAlign: "center", fontSize: "12px" }}>₹{CurrencyConverter.toCurrency(product.mrp || 0, "INR")}</td>
                                             <td style={{ textAlign: "center", fontSize: "12px" }}>{product.quantity}</td>
                                             <td style={{ textAlign: "center", fontSize: "12px" }}>₹{CurrencyConverter.toCurrency(product.lineTotalSalePrice || 0, "INR")}</td>
                                             <td style={{ textAlign: "center", fontSize: "12px" }}>₹{CurrencyConverter.toCurrency(product.lineTotalDiscount || 0, "INR")}</td>
                                             <td style={{ textAlign: "center", fontSize: "12px" }}>₹{CurrencyConverter.toCurrency(product.lineTotalBasePrice || 0, "INR")}</td>
                                             <td style={{ textAlign: "center", fontSize: "12px" }}>₹{CurrencyConverter.toCurrency(product.lineTotalTaxAmount || 0, "INR")}</td>
                                             <td style={{ textAlign: "center", fontSize: "12px" }}>₹{CurrencyConverter.toCurrency(product.lineTotalSalePrice || 0, "INR")}</td>
                                          </tr>
                                       ))}
                                       <tr>
                                          <td></td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 600, }}>
                                             Total
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 600, }}>
                                             Total Items
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 600, }}>
                                             {data.totalqty}{" "}
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 600, }}>
                                             ₹{CurrencyConverter.toCurrency(data.totalsaleprice || 0, "INR")}
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 600, }}>
                                             ₹{CurrencyConverter.toCurrency(data.totaldiscount || 0, "INR")}
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 600, }}>
                                             ₹{CurrencyConverter.toCurrency(data.totalbaseprice || 0, "INR")}
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 600, }}>
                                             ₹{CurrencyConverter.toCurrency(data.totaltaxamount || 0, "INR")}
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 600, }}>
                                             ₹{CurrencyConverter.toCurrency(data.grandtotal || 0, "INR")}
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ textAlign: "left", fontSize: "12px", fontWeight: 600, }} colSpan="5">
                                             Total Amount In Words{" "}
                                          </td>
                                          <td style={{ textAlign: "right", fontSize: "12px", fontWeight: 500, }} colSpan="3">
                                             {" "} Total Amount Before Tax
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 500, }}>
                                             ₹{CurrencyConverter.toCurrency(data.totalbaseprice || 0, "INR")}
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 500, }} colSpan="5" rowSpan="5">
                                             {data.amountinwords}
                                          </td>
                                       </tr>
                                       {data.salefrom?.statecode && data.saleto?.statecode && data.salefrom.statecode !== data.saleto.statecode ? (
                                          <tr>
                                             <td style={{ textAlign: "right", fontSize: "12px", fontWeight: 500, }} colSpan="3">
                                                IGST
                                             </td>
                                             <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 500, }}>
                                                ₹{CurrencyConverter.toCurrency(data.igstamount || 0, "INR")}
                                             </td>
                                          </tr>
                                       ) : (
                                          <>
                                             <tr>
                                                <td style={{ textAlign: "right", fontSize: "12px", fontWeight: 500, }} colSpan="3">
                                                   SGST
                                                </td>
                                                <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 500, }}>
                                                   ₹{CurrencyConverter.toCurrency(data.sgstamount || 0, "INR")}
                                                </td>
                                             </tr>
                                             <tr>
                                                <td style={{ textAlign: "right", fontSize: "12px", fontWeight: 500, }} colSpan="3">
                                                   CGST
                                                </td>
                                                <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 500, }}>
                                                   ₹{CurrencyConverter.toCurrency(data.cgstamount || 0, "INR")}
                                                </td>
                                             </tr></>
                                       )}
                                       <tr>
                                          <td style={{ textAlign: "right", fontSize: "12px", fontWeight: 500, }} colSpan="3">
                                             TOTAL
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "12px", fontWeight: 500, }}>
                                             ₹{CurrencyConverter.toCurrency(data.totalsaleprice || 0, "INR")}
                                          </td>
                                       </tr>

                                       <tr>
                                          <td style={{ textAlign: "right", fontSize: "14px", fontWeight: 600, }} colSpan="3">
                                             Grand Total (Rounded)
                                          </td>
                                          <td style={{ textAlign: "center", fontSize: "14px", fontWeight: 600, }}>
                                             ₹{CurrencyConverter.toCurrency(data.grandtotal || 0, "INR")}
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                                 <table width="100%">
                                    <tbody>
                                       <tr>
                                          <td style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px", color: "black", }}>
                                             *** Thank You ! Visit Us Again ***{" "}
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <table className="items" width="100%" style={{ borderCollapse: "collapse" }} cellPadding="8">
                                 <tbody>
                                    <tr>
                                       <td style={{ verticalAlign: "middle", width: "52.5%", border: "0.5mm solid #e0e0e0", textAlign: "left", padding: "8px", }}>
                                          <span style={{ fontSize: "12px", fontWeight: 600 }}>
                                             Declaration:
                                          </span>
                                          <br />
                                          <span style={{ fontSize: "12px" }}>
                                             We declare that this Invoice shows the actual price
                                             of the goods described and that all particulars are
                                             true and correct.
                                          </span>
                                       </td>
                                       <td style={{ width: "50%", fontSize: "12px", fontWeight: "500", border: "0.5mm solid #e0e0e0", textAlign: "center", padding: "8px", }}>
                                          * Computer Generated Document No sign Required
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer d-block">
                        <div className='fw-600'>PAID SUMMARY</div>

                        <table className="items table-bordered" width="100%" cellPadding="8">
                           <tbody className='text-center'>
                              <tr>
                                 <th>CASH</th>
                                 <th>CARD</th>
                                 <th>ONLINE</th>
                                 <th>UPI</th>
                                 <th>DISCOUNT</th>
                              </tr>
                              <tr>
                                 <td className='text-center'>₹{CurrencyConverter.toCurrency(data.cashamount || 0, "INR")}</td>
                                 <td className='text-center'>₹{CurrencyConverter.toCurrency(data.cardamount || 0, "INR")}</td>
                                 <td className='text-center'>₹{CurrencyConverter.toCurrency(data.onlineamount || 0, "INR")}</td>
                                 <td className='text-center'>₹{CurrencyConverter.toCurrency(data.upiamount || 0, "INR")}</td>
                                 <td className='text-center'>₹{CurrencyConverter.toCurrency(data.discountamount || 0, "INR")}</td>
                              </tr>
                           </tbody>
                        </table>


                     <button onClick={onClose} type="button" data-bs-dismiss="modal" className="btn btn-light btn-sm text-left">
                        <i className="lni lni-close fs-15"></i> Close
                     </button>
                     <button onClick={printSale} className="btn btn-info btn-sm text-right" id="printreceiptbtn">
                        <i className="lni lni-printer fs-15"></i> Print
                     </button>
                     {/* <button className="btn btn-warning btn-sm text-right" id="generatebillpdfbtn">
                        <i className="bx bx-download fs-15"></i> Download PDF
                    </button> */}
                     {/*
                    <button type="button" onclick="editsalecart()" className="btn btn-dark btn-sm text-right" fdprocessedid="iso258">
                        <i className="lni lni-pencil"></i> Edit Bill Receipt
                    </button>
                    <button onclick="printreceipt()" className="btn btn-info btn-sm text-right" id="printreceiptbtn" fdprocessedid="njp7f9">
                        <i className="lni lni-printer"></i> Print
                    </button>*/} {/*
                    */} {/*
                    <button className="btn btn-warning btn-sm text-right" onclick="downloadBillPDF()" id="generatebillpdfbtn" fdprocessedid="kedald">
                        {" "}
                        <i className="bx bx-download"></i> Download PDF
                    </button> */} {/*
                    <button className="btn btn-success btn-sm text-right" onclick="shareBill()" fdprocessedid="l1y2of">
                        <i className="lni lni-whatsapp"></i> Share on Whatsapp
                    </button> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};



export default ViewSale;
