import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import {
  setAuthCookie,
  resetAuthCookie,
  getAccessStoreToken,
  getRefreshStoreToken,
} from "../components/utils/CookieUtils";
import checkNetworkStatus from "../components/reusable/NetworkStatus";
import uRLs from "../components/utils/BaseUtils";
import { useNavigate } from "react-router-dom";

// Create the AuthContext
export const AuthContext = createContext();

const isPublicRoute = (path) => {
  return /^\/invite-sub\/.+$/.test(path);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const checkAuthStatus = async () => {
    const currentPath = window.location.pathname;

    if (isPublicRoute(currentPath)) {
      setIsLoading(false);
      return;
    }

    try {

      if (!checkNetworkStatus()) {
        setIsLoading(false);
        return;
      }

      const accessToken = getAccessStoreToken();
      if (accessToken && (await validateAccessToken(accessToken))) {
        setIsAuthenticated(true);
        return;
      }

      const refreshToken = getRefreshStoreToken();
      if (refreshToken && (await refreshAccessToken(refreshToken))) {
        setIsAuthenticated(true);
        return;
      }

      resetAuthCookie();
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      console.error("Error during authentication check:", error.message);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const validateAccessToken = async (token) => {
    try {
      const response = await axios.get(`${uRLs.API_URL}auth-token`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 200) {
        localStorage.setItem("NgBlLoggedUser", JSON.stringify(response.data.results));
        return true;
      }
    } catch (error) {
      console.warn("Access token validation failed:", error.message);
    }
    return false;
  };

  const refreshAccessToken = async (token) => {
    try {
      const response = await axios.get(`${uRLs.API_URL}create-token`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 200) {
        setAuthCookie({ accessStoreToken: response.data.results.accessToken });
        return true;
      }
    } catch (error) {
      console.warn("Refresh token failed:", error.message);
    }
    return false;
  };

  const handleLogout = () => {
    localStorage.removeItem("NgBlLoggedUser");
    resetAuthCookie();
    setIsAuthenticated(false);
    navigate("/login");
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};