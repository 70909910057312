import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import checkNetworkStatus from "../reusable/NetworkStatus";
import { getAccessStoreToken } from "../../components/utils/CookieUtils";
import ErrorMsg from "../messages/ErrorMessages";
import uRLs from "../utils/BaseUtils";
import useShowAlert from "../utils/AlertUtils";
import priceCalculator from "@heartinz/gst_india_product_price_calculator";
import CurrencyConverter from '@heartinz/currency_subunit_converter';
import SuccessMsg from "../messages/SuccessMessages";

const accessStoreToken = getAccessStoreToken();

const AddProduct = ({ listRecentProduct }) => {
  const [isbtnLoading, setButtonLoading] = useState(false);
  const [isbtnBarLoading, setBarButtonLoading] = useState(false);
  const [error, setErrors] = useState("");
  const [isSelectedRadio, setSelectedRadio] = useState("SALEPRICE");
  const showAlert = useShowAlert();

  const productCodeRef = useRef(null);
  const productHsnsacRef = useRef(null);
  const productTitleRef = useRef(null);
  const productMrpRef = useRef(null);
  const productsalepriceRef = useRef(null);
  const producttaxpercentageRef = useRef(null);
  const productbasepriceRef = useRef(null);

  const [isProductData, setProductData] = useState({
    code: "",
    hsnsac: "",
    title: "",
    mrp: 0,
    saleprice: 0,
    taxpercentage: 0,
    discount: 0,
    baseprice: 0,
    taxamount: 0,
  });

  const handleCalculate = (updatedData) => {
    var { mrp, saleprice, baseprice, taxpercentage } = updatedData;
    let numericMrp = CurrencyConverter.toSubunit(mrp || 0, 'INR');
    let numericSaleprice = CurrencyConverter.toSubunit(saleprice || 0, 'INR');
    let numericBaseprice = CurrencyConverter.toSubunit(baseprice || 0, 'INR');
    let numericTaxpercentage = parseInt(taxpercentage);
    let quanity = parseInt(1);
    let calculationResult;
    let lock = isSelectedRadio;
    try {
      calculationResult = priceCalculator.getAllPriceList(
        numericMrp,
        numericSaleprice,
        numericBaseprice,
        numericTaxpercentage,
        quanity,
        lock
      );

      if(lock == "SALEPRICE") {
        if(numericMrp != calculationResult.mrp) {
          setProductData((prevData) => ({
            ...prevData,
            mrp: CurrencyConverter.toCurrency(calculationResult.mrp, 'INR'),
            discount: CurrencyConverter.toCurrency(calculationResult.discount, 'INR'),
            taxamount: CurrencyConverter.toCurrency(calculationResult.gstAmount, 'INR'),
            baseprice: CurrencyConverter.toCurrency(calculationResult.basePrice, 'INR')
          }));
        }
        setProductData((prevData) => ({
          ...prevData,
          discount: CurrencyConverter.toCurrency(calculationResult.discount, 'INR'),
          taxamount: CurrencyConverter.toCurrency(calculationResult.gstAmount, 'INR'),
          baseprice: CurrencyConverter.toCurrency(calculationResult.basePrice, 'INR')
        }));
      } else if(lock =="BASEPRICE") {
        if(numericMrp != calculationResult.mrp) {
          setProductData((prevData) => ({
            ...prevData,
            mrp: CurrencyConverter.toCurrency(calculationResult.mrp, 'INR'),
            discount: CurrencyConverter.toCurrency(calculationResult.discount, 'INR'),
            taxamount: CurrencyConverter.toCurrency(calculationResult.gstAmount, 'INR'),
            saleprice: CurrencyConverter.toCurrency(calculationResult.salePrice, 'INR')
          }));
        }
        setProductData((prevData) => ({
          ...prevData,
          discount: CurrencyConverter.toCurrency(calculationResult.discount, 'INR'),
          taxamount: CurrencyConverter.toCurrency(calculationResult.gstAmount, 'INR'),
          saleprice: CurrencyConverter.toCurrency(calculationResult.salePrice, 'INR')
        }));
      }
    } catch (error) {
      showAlert("warning", error.message || ErrorMsg.INVALID_INPUT);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value;
    if (name === "code") {
      const regex = /^[^\s]*$/;
      if (!regex.test(value)) inputValue = "";
    }

    if (name === "mrp" || name === "saleprice" || name === "baseprice") {
      const regex = /^\d+(\.\d{0,2})?$/;
      if (!regex.test(value)) inputValue = "";
    }
    if (name === "taxpercentage") {
      const allowedValues = [0, 5, 12, 18, 24];
      if (!allowedValues.includes(Number(value))) inputValue = 0;
    }
    const updatedData = {
      ...isProductData,
      [name]: inputValue,
    };
    setProductData(updatedData);
    handleCalculate(updatedData);
  };

  const handleRadioChange = (inputName) => {
    setSelectedRadio(inputName);
  };

  const handleGenerateBarcode = async () => {
    setBarButtonLoading(true);
    checkNetworkStatus();
    try {
      if (!accessStoreToken) {
        showAlert("warning", ErrorMsg.TOKEN_MISSING);
        return;
      }
      var data = {};
      const response = await axios.post(`${uRLs.APII_URL}create-barcode`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });
      const newBarcode = response.data.result.barcode;
      setProductData((prevData) => ({
        ...prevData,
        code: newBarcode,
      }));
    } catch (error) {
      if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
        showAlert("error", ErrorMsg.NO_INTERNET);
      } else {
        showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
      }
    } finally {
      productCodeRef.current?.focus();
      setBarButtonLoading(false);
    }
  };

  const onAdd = (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    handleAdd(isProductData);
    resetForm();
  };

  const resetForm = () => {
    setProductData({
      code: "",
      hsnsac: "",
      title: "",
      mrp: 0,
      saleprice: 0,
      taxpercentage: 0,
      discount: 0,
      baseprice: 0,
      taxamount: 0,
    });
    setErrors("");
    productCodeRef.current?.focus();
  };

  const validateForm = () => {
    const errors = {};
    if (!isProductData.code) {
      errors.code = ErrorMsg.CODE_EMPTY;
    } else if (isProductData.code && !/^[^\s]*$/.test(isProductData.code)) {
      errors.name = ErrorMsg.INVALID_INPUT;
    }

    else if (!isProductData.title) {
      errors.title = ErrorMsg.TITLE_EMPTY;
    }

    else if (isProductData.mrp === undefined || isProductData.mrp === null || isProductData.mrp === "") {
      errors.mrp = ErrorMsg.MRP_EMPTY;
    } else if (isProductData.mrp && !/^\d*\.?\d{0,2}$/.test(isProductData.mrp)) {
      errors.mrp = ErrorMsg.INVALID_INPUT;
    }

    else if (isProductData.saleprice === undefined || isProductData.saleprice === null || isProductData.saleprice === "") {
      errors.saleprice = ErrorMsg.SALEPRICE_EMPTY;
    } else if (isProductData.saleprice && !/^\d*\.?\d{0,2}$/.test(isProductData.saleprice)) {
      errors.saleprice = ErrorMsg.INVALID_INPUT;
    }

    else if (isProductData.taxpercentage === undefined || isProductData.taxpercentage === null || isProductData.taxpercentage === "") {
      errors.taxpercentage = ErrorMsg.TAX_EMPTY;
    } else if (isProductData.taxpercentage && !/^[0-9]*$/.test(isProductData.taxpercentage)) {
      errors.taxpercentage = ErrorMsg.INVALID_INPUT;
    }

    else if (isProductData.discount === undefined || isProductData.discount === null || isProductData.discount === "") {
      errors.discount = ErrorMsg.DISCOUNT_EMPTY;
    } else if (isProductData.discount && !/^\d*\.?\d{0,2}$/.test(isProductData.discount)) {
      errors.discount = ErrorMsg.INVALID_INPUT;
    }

    else if (isProductData.baseprice === undefined || isProductData.baseprice === null || isProductData.baseprice === "") {
      errors.baseprice = ErrorMsg.BASEPRICE_EMPTY;
    } else if (isProductData.baseprice && !/^\d*\.?\d{0,2}$/.test(isProductData.baseprice)) {
      errors.baseprice = ErrorMsg.INVALID_INPUT;
    }

    else if (isProductData.taxamount === undefined || isProductData.taxamount === null || isProductData.taxamount === "") {
      errors.taxamount = ErrorMsg.TAXAMOUNT_EMPTY;
    } else if (isProductData.taxamount && !/^\d*\.?\d{0,2}$/.test(isProductData.taxamount)) {
      errors.taxamount = ErrorMsg.INVALID_INPUT;
    }

    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      const firstErrorField = Object.keys(errors)[0];
      switch (firstErrorField) {
        case 'code':
          productCodeRef.current?.focus();
          break;
        case 'title':
          productTitleRef.current?.focus();
          break;
        case 'mrp':
          productMrpRef.current?.focus();
          break;
        case 'saleprice':
          productsalepriceRef.current?.focus();
          break;
        case 'taxpercentage':
          producttaxpercentageRef.current?.focus();
          break;
        case 'baseprice':
          productbasepriceRef.current?.focus();
          break;
        default:
          break;
      }
    }
    return Object.keys(errors).length === 0;
  };

  const handleClose = async () => {
    resetForm();
  }

  const handleAdd = async (newProductData) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const data = {
        code: newProductData.code,
        hsnsac: newProductData.hsnsac,
        title: newProductData.title,
        mrp: CurrencyConverter.toSubunit(newProductData.mrp, 'INR'),
        saleprice: CurrencyConverter.toSubunit(newProductData.saleprice, 'INR'),
        taxpercentage: newProductData.taxpercentage,
        discount: CurrencyConverter.toSubunit(newProductData.discount, 'INR'),
        baseprice: CurrencyConverter.toSubunit(newProductData.baseprice, 'INR'),
        taxamount: CurrencyConverter.toSubunit(newProductData.taxamount, 'INR'),
        islock: isSelectedRadio,
      };
      const response = await axios.post(`${uRLs.API_URL}add-product`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });
      showAlert("success", SuccessMsg.PRODUCT_ADDED);
      listRecentProduct();
    } catch (error) {
      if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
        showAlert("error", ErrorMsg.NO_INTERNET);
      } else {
        showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
      }
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    productCodeRef.current?.focus();
  }, []);

  return (
    <div className="card mt-3">
      <div className="card-body">
        <div className="row g-3">
          <div className="col-lg-8 col-xl-8 col-md-6 col-sm-6">
            <label htmlFor="phone" className="form-label">Barcode / SKU / Code <span className="text-danger">*</span></label>
            <div className="input-group mb-0">
              <input
                type="text"
                className="form-control"
                name="code"
                autoComplete="off"
                ref={productCodeRef}
                value={isProductData.code}
                onInput={handleChange}
              />
              {isbtnBarLoading ? (
                <button className="btn btn-primary pb-0 text-right" disabled>
                  <CircularProgress size={22} className="text-white" />
                </button>
              ) : (
                <button type="button" className="btn btn-primary text-right"
                  onClick={handleGenerateBarcode}>
                  <i className="bx bx-barcode"></i> Generate
                </button>
              )}
            </div>
            {error.code && <div className="invalid-feedback">{error.code}</div>}
          </div>

          <div className="col-lg-4">
            <label htmlFor="hsnsac" className="form-label"> HSN/SAC </label>
            <input
              type="text"
              className="form-control"
              name="hsnsac"
              autoComplete="off"
              ref={productHsnsacRef}
              value={isProductData.hsnsac}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-12">
            <label htmlFor="title" className="form-label">
              Title <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              autoComplete="off"
              ref={productTitleRef}
              value={isProductData.title}
              onChange={handleChange}
            />
            {error.title && <div className="invalid-feedback">{error.title}</div>}
          </div>

          <div className="col-lg-4">
            <label htmlFor="mrp" className="form-label"> MRP </label>
            <input
              type="text"
              className="form-control"
              name="mrp"
              autoComplete="off"
              ref={productMrpRef}
              value={isProductData.mrp}
              onFocus={(e) => e.target.select()}
              onChange={handleChange}
            />
            {error.mrp && <div className="invalid-feedback">{error.mrp}</div>}
          </div>

          <div className="col-lg-4">
            <label htmlFor="saleprice" className="form-label">
              Sale Price <span className="text-danger">*</span>
            </label>

            <div className="input-container position-relative">
              <input
                type="text"
                name="saleprice"
                autoComplete="off"
                ref={productsalepriceRef}
                value={isProductData.saleprice}
                className="form-control"
                onFocus={(e) => e.target.select()}
                onClick={() => handleRadioChange("SALEPRICE")}
                style={{ paddingLeft: "30px" }}
                onChange={handleChange}
              />
              <input
                type="radio"
                name="selectInput"
                checked={isSelectedRadio === "SALEPRICE"}
                className="position-absolute"
                style={{
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                }}
              />
            </div>
            {error.saleprice && <div className="invalid-feedback">{error.saleprice}</div>}
          </div>

          <div className="col-lg-4">
            <label htmlFor="FisrtName" className="form-label">
              Tax(%) <span className="text-danger">*</span>
            </label>
            <select
              name="taxpercentage"
              className="form-control"
              ref={producttaxpercentageRef}
              value={isProductData.taxpercentage}
              onChange={handleChange}
            >
              <option value="0">0</option>
              <option value="5">5</option>
              <option value="12">12</option>
              <option value="18">18</option>
              <option value="24">24</option>
            </select>
          </div>

          <div className="col-lg-4">
            <label htmlFor="discount" className="form-label">
              Discount
            </label>
            <input
              readOnly=""
              type="text"
              name="discount"
              autoComplete="off"
              value={isProductData.discount}
              className="form-control"
              disabled
            />
          </div>

          <div className="col-lg-4">
            <label htmlFor="baseprice" className="form-label">
              Base Price
            </label>

            <div className="input-container position-relative">
              <input
                type="text"
                name="baseprice"
                autoComplete="off"
                ref={productbasepriceRef}
                onClick={() => handleRadioChange("BASEPRICE")}
                onChange={handleChange}
                value={isProductData.baseprice}
                onFocus={(e) => e.target.select()}
                className="form-control"
                style={{ paddingLeft: "30px" }}
              />
              <input
                type="radio"
                name="selectInput"
                className="position-absolute"
                checked={isSelectedRadio === "BASEPRICE"}
                style={{
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                }}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <label htmlFor="taxamount" className="form-label">
              Tax Amount
            </label>

            <input
              readOnly=""
              type="text"
              name="taxamount"
              autoComplete="off"
              className="form-control"
              value={isProductData.taxamount}
              disabled
            />
          </div>

          <div className="mt-4">
            <button type="button" className="btn btn-outline-secondary text-left" onClick={handleClose}>
              <i className="bx bx-x"></i> Cancel
            </button>
            {isbtnLoading ? (
              <button className="btn btn-primary pb-0 text-right" disabled>
                <CircularProgress size={22} className="text-white" />
              </button>
            ) : (
              <button type="button" className="btn btn-primary text-right"
                onClick={onAdd}>
                <i className="ng-ng-add-icon"></i> Add Product
              </button>
            )}
          </div>

        </div>
      </div>
    </div>
  )
};

export default AddProduct;
