import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sendEmailOTP, verifyOtpCode } from "../utils/ApiUtils";
import { setAuthCookie } from "../utils/CookieUtils";
import { authStore } from "../utils/AuthUtils";
import EmailStep from "./EmailStep";
import OtpStep from "./OtpStep";
import ErrorMsg from "../messages/ErrorMessages";
import SuccessMsg from "../messages/SuccessMessages";
import useShowAlert from "../utils/AlertUtils";

const Login = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const showAlert = useShowAlert();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault(); 
    setLoading(true);
    setError("");
    if (!email) {
      setError(ErrorMsg.EMPTY_EMAIL);
      setLoading(false);
      return;
    }
    if (!emailRegex.test(email)) {
      setError(ErrorMsg.INVALID_EMAIL);
      setLoading(false);
      return;
    }
    try {
      const verifyToken = await sendEmailOTP(email, showAlert);
      if (verifyToken) {
        setEmail("");
        setAuthCookie({ "verifyToken": verifyToken });
        setStep(2);
      } else {
        showAlert("warning", ErrorMsg.OTP_SEND_FAIL);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const otpCode = otp.join("");
    if (!otpCode) {
      setError(ErrorMsg.OTP_EMPTY);
      setLoading(false);
      return;
    }
  
    if (otpCode.length !== 6) {
      setError(ErrorMsg.OTP_INVALID);
      setLoading(false);
      return;
    }
  
    try {
      const accessToken = await verifyOtpCode(otpCode, showAlert);
      if (!accessToken) {
        showAlert("warning", ErrorMsg.TOKEN_MISSING);
        return;
      }
      try {
        const successRes = await authStore(accessToken, showAlert);
        if (successRes) {
          setIsAuthenticated(true);
          showAlert("success", SuccessMsg.LOGIN_SUCCESS);
          const snackbarDisplayTime = 500;
          setTimeout(() => {
            window.location.reload();
          }, snackbarDisplayTime);
        }
      } catch (innerError) {
        handleError(innerError);
      }
    } catch (outerError) {
      handleError(outerError);
    } finally {
      setLoading(false);
    }
  };
  
  const handleError = (error) => {
    if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
      showAlert("error", ErrorMsg.NO_INTERNET);
    } else if (error.response && error.response.data?.message) {
      showAlert("warning", error.response.data.message);
    } else {
      showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
    }
  };

  return (
    <div className="wrapper">
      <div className="section-authentication-cover">
        <div className="row g-0">
          <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex">
            <div className="card shadow-none bg-transparent shadow-none rounded-0 mb-0">
              <div className="card-body">
                <img
                  src="/assets/images/v1/login-images/auth-login-image.png"
                  alt="Cover"
                  className="img-fluid"
                  width="600"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center">
            <div className="card rounded-0 m-3 shadow-none bg-transparent mb-0">
              <div className="card-body p-sm-5">
                <div className="text-center mb-3">
                  <img src="/assets/images/v1/logo-icon.png" width="60" alt="Login" />
                </div>
                {step === 1 && (
                  <EmailStep
                    email={email}
                    setEmail={setEmail}
                    onSubmit={handleEmailSubmit}
                    loading={loading}
                    error={error}
                  />
                )}
                {step === 2 && (
                  <OtpStep
                    email={email}
                    otp={otp}
                    setOtp={setOtp}
                    onSubmit={handleOTPSubmit}
                    loading={loading}
                    error={error}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;