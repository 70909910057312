import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAccessStoreToken } from "../../components/utils/CookieUtils";
import ErrorMsg from "../messages/ErrorMessages";
import uRLs from "../utils/BaseUtils";
import useShowAlert from "../utils/AlertUtils";
import { Skeleton } from "@mui/material";
import checkNetworkStatus from "../reusable/NetworkStatus";
import CurrencyConverter from "@heartinz/currency_subunit_converter";
import DeleteModal from "../reusable/DeleteModal";
import SuccessMsg from "../messages/SuccessMessages";
import { useNavigate } from "react-router-dom";
const accessStoreToken = getAccessStoreToken();

const ListProduct = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProductData, setProductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const navigate = useNavigate();

  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-label-green";
      case "inactive":
        return "bg-label-danger";
      default:
        return "bg-label-secondary";
    }
  };

  const showAlert = useShowAlert();

  const handleList = async () => {
    setIsLoading(true);
    checkNetworkStatus();
    try {
      if (!accessStoreToken) {
        showAlert("warning", ErrorMsg.TOKEN_MISSING);
        return;
      }
      const data = { searchtxt: "" };
      const response = await axios.post(`${uRLs.API_URL}list-product`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });
      setProductData(response.data.results);
      setFilteredData(response.data.results);
    } catch (error) {
      if (error.message === "NO_INTERNET") {
        showAlert("error", ErrorMsg.NO_INTERNET);
      } else if (error.code === "ERR_NETWORK") {
        showAlert("error", ErrorMsg.SERVER_DOWN);
      } else if (error.response && error.response.data?.message) {
          if(!error.response.data.code === 'NOT_FOUND') {
            showAlert("warning", error.response.data.message);
          }
      } 
      else if (error.code === 'ERR_BAD_REQUEST')  {
        showAlert("warning", ErrorMsg.URL_NOT_FOUND);
      } else {
        showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = isProductData.filter((product) =>
      product.title?.toLowerCase().includes(query) ||
      product.code?.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  };

  const handleDelete = async () => {
    checkNetworkStatus();
    try {
      const data = { productid: selectedProductId };
      await axios.post(`${uRLs.API_URL}delete-product`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessStoreToken}`,
        },
      });

      // Update state after successful deletion
      const updatedProducts = isProductData.filter(
        (product) => product._id !== selectedProductId
      );
      setProductData(updatedProducts);
      setFilteredData(updatedProducts);

      showAlert("success", SuccessMsg.PRODUCT_DELETED);
    } catch (error) {
      if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
        showAlert("error", ErrorMsg.NO_INTERNET);
      } else {
        showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
      }
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const openDeleteModal = (productId) => {
    setSelectedProductId(productId); // Set product ID for deletion
    setDeleteModalOpen(true); // Open modal
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false); // Close modal
    setSelectedProductId(null); // Clear selected product
  };

  const handleLink = () =>{
    navigate('/product');
  }

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="page-content">
            <div className="page-breadcrumb d-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">List Product</div>
              <div className="ms-auto">
                <button
                  type="button"
                  className="btn btn-primary mt-2 mt-lg-0" onClick={handleLink}
                >
                  <i className="ng-ng-product-add-icon"></i> Add Product
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="card radius-10 mb-0">
                  <div className="card-body px-0">
                    <div className="row px-3">
                      <div className="col-lg-5 me-auto">
                        <div className="ms-auto position-relative">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search product by code, title, etc..."
                              value={searchQuery}
                              onChange={handleSearch}
                            />
                            <button className="input-group-text">
                              <i className="ng-ng-search-icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive mt-3">
                      <table className="table align-middle mb-0">
                        <thead className="table-light text-center">
                          <tr>
                            <th>S.No</th>
                            <th>Barcode</th>
                            <th>Title</th>
                            <th>MRP</th>
                            <th>Sale Price</th>
                            <th>Tax</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            [...Array(8)].map((_, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  <Skeleton variant="text" width={200} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={200} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                                <td className="text-center">
                                  <Skeleton variant="text" width={100} />
                                </td>
                              </tr>
                            ))
                          ) : filteredData.length > 0 ? (
                            filteredData.map((item, index) => (
                              <tr key={item.id || index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">
                                  <span className="badge rounded-pill bg-label-blue">{item.code}</span>
                                </td>
                                <td className="text-center" width="480">
                                  <span className="text-heading fw-medium text-truncate">
                                    {item.title}
                                  </span>
                                </td>
                                <td className="text-center">
                                  ₹{CurrencyConverter.toCurrency(item.mrp || 0, "INR")}
                                </td>
                                <td className="text-center">
                                  ₹{CurrencyConverter.toCurrency(item.saleprice || 0, "INR")}
                                </td>
                                <td className="text-center">
                                  <span className="badge bg-light-purple">{item.taxpercentage ? `${item.taxpercentage}%` : "0%"}</span>
                                </td>
                                <td className="text-center">
                                  {item.status && (
                                    <span
                                      className={`badge rounded-pill ${getColor(item.status)}`}
                                    >
                                      {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <div className="order-actions">
                                    <button
                                      type="button"
                                      className="btn-label-danger shadow-none me-2"
                                      onClick={() => openDeleteModal(item._id)}
                                    >
                                      <i className="bx bx-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="8" className="text-center p-2">
                                <span className="text-muted">No product found in the store. add some customers to get started.</span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onConfirm={handleDelete}
          onClose={closeDeleteModal}
        />
      )}
    </>
  );
};

export default ListProduct;