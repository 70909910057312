import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress } from "@mui/material";
import StateDropdown from '../reusable/StateDropDown';
import ErrorMsg from '../messages/ErrorMessages';

const EditCutomer = ({ editData, onClose, onEdit, isbtnLoading }) => {

  const [customerData, setCustomerData] = useState({
    name: "",
    phone: "",
    email: "",
    addressline1: "",
    addressline2: "",
    state: "",
    statecode: "",
    city: "",
    district: "",
    country: "",
    pincode: "",
    cinnumber: "",
    gstnumber: "",
    pannumber: "",
    referid: "",
  });

  const [isState, setSelectedState] = useState("");
  const [isStatecode, setSelectedStateCode] = useState("");

  const [error, setErrors] = useState({});  
  const customerNameRef = useRef(null);
  const customerPhoneRef = useRef(null);
  const customerEmailRef = useRef(null);
  const customerPincodeRef = useRef(null);
  const customerGSTRef = useRef(null);
  const customerPanRef = useRef(null);
  const customerCinRef = useRef(null);
  const customerRidRef = useRef(null);

  const validateForm = () => {
    const errors = {};
  
    if (customerData.name && !/^[a-zA-Z0-9\s]+$/.test(customerData.name)) {
      errors.name = ErrorMsg.INVALID_INPUT;
    }
  
    if (!customerData.phone) {
      errors.phone = ErrorMsg.EMPTY_PHONE;
    } else if (!/^[0-9]{10}$/.test(customerData.phone)) {
      errors.phone = ErrorMsg.INVALID_PHONE;
    }
  
    if (customerData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerData.email)) {
      errors.email = ErrorMsg.INVALID_EMAIL;
    }
  
    if (customerData.pincode && !/^[0-9]{6}$/.test(customerData.pincode)) {
      errors.pincode = ErrorMsg.INVALID_PINCODE;
    }
  
    if (customerData.gstnumber && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/.test(customerData.gstnumber)) {
      errors.gstnumber = ErrorMsg.INVALID_GST_NUMBER;
    }
  
    if (customerData.pannumber && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(customerData.pannumber)) {
      errors.pannumber = ErrorMsg.INVALID_PAN_NUMBER;
    }
  
    if (customerData.cinnumber && !/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/.test(customerData.cinnumber)) {
      errors.cinnumber = ErrorMsg.INVALID_CIN_NUMBER;
    }
  
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      const firstErrorField = Object.keys(errors)[0];
      switch (firstErrorField) {
        case 'name':
          customerNameRef.current?.focus();
          break;
        case 'phone':
          customerPhoneRef.current?.focus();
          break;
        case 'email':
          customerEmailRef.current?.focus();
          break;
        case 'pincode':
          customerPincodeRef.current?.focus();
          break;
        case 'gstnumber':
          customerGSTRef.current?.focus();
          break;
        case 'pannumber':
          customerPanRef.current?.focus();
          break;
        case 'cinnumber':
          customerCinRef.current?.focus();
          break;
        default:
          break;
      }
    }
    
    return Object.keys(errors).length === 0;
  };

  const resetForm = () => {
    setCustomerData({
      name: "",
      phone: "",
      email: "",
      addressline1: "",
      addressline2: "",
      state: "",
      statecode: "",
      city: "",
      district: "",
      country: "India",
      pincode: "",
      cinnumber: "",
      gstnumber: "",
      pannumber: "",
      referid: "",
    });
    setErrors("");
    setSelectedStateCode("");
    setSelectedState("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (["phone", "pincode"].includes(name) && !/^[0-9]*$/.test(value)) {
      updatedValue = "";
    }
    setCustomerData((prev) => ({ ...prev, [name]: updatedValue }));
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleEdit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    const updatedData = { ...customerData };
    onEdit(updatedData);
  };

  const handleStateChange = (e) => {
    const { name, value } = e.target;
    const label = e.target.options[e.target.selectedIndex].text;
    setSelectedStateCode(value);
    setSelectedState(label);
    setCustomerData((prev) => ({ ...prev, ["state"]: label, ["statecode"]: value }));
  };

  const stopEventPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    if (editData) {
      setCustomerData(editData);
      setSelectedState(editData.state);
      setSelectedStateCode(editData.statecode);
    }
  }, [editData]);

  return (
    <div>
      <div className="modal-backdrop show" onClick={handleClose}></div>
      <div className="modal show" id="CustomerModal" tabIndex="-1" aria-modal="true" role="dialog"
        onClick={handleClose} style={{ display: "block" }}>

        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          onClick={stopEventPropagation}>
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title">Update Customer</h6>
              <button type="button" className="btn-close" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close" fdprocessedid="jgxcje"></button>
            </div>
                <div className="modal-body bg-light">
                    <div className="card">
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-lg-6">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text"
                              className="form-control"
                              name="name"
                              autoComplete="off"
                              value={customerData.name}
                              onChange={handleChange}
                              ref={customerNameRef}
                            />
                            {error.name && (
                              <div className="error-message invalid-feedback" > 
                                {error.name}
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="phone" className="form-label">Phone <span className="text-danger">*</span></label>
                            <div className="input-group">
                              <span className="input-group-text" >+91</span>
                              <input type="text" className="form-control"
                                autoComplete="off"
                                maxLength={10}
                                name="phone"
                                ref={customerPhoneRef}
                                value={customerData.phone}
                                onChange={handleChange}
                              />
                            </div>
                            {error.phone && (
                              <div className="error-message invalid-feedback" > 
                                {error.phone}
                              </div>
                            )} 
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" fdprocessedid="5b1j1a">
                                Billing Details (Optional)
                              </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                              <div className="accordion-body">
                                <div className="row g-3">

                                  <div className="col-lg-6">
                                    <label htmlFor="bsValidation1" className="form-label">Address Line 1</label>
                                    <textarea type="text" className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      name="addressline1"
                                      value={customerData.addressline1}>
                                    </textarea>
                                  </div>

                                  <div className="col-lg-6">
                                    <label htmlFor="bsValidation1" className="form-label">Address Line 2</label>
                                    <textarea type="text" className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      name="addressline2"
                                      value={customerData.addressline2}></textarea>
                                  </div>

                                  <div className="col-lg-6">
                                    <label htmlFor="bsValidation1" className="form-label">District</label>
                                    <input type="text" className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      name="district"
                                      value={customerData.district} />
                                  </div>

                                  <div className="col-lg-6">
                                    <label htmlFor="bsValidation1" className="form-label">State</label>
                                    <StateDropdown stateChange={handleStateChange} value={isStatecode} />
                                  </div>


                                  <div className="col-lg-3">
                                    <label htmlFor="bsValidation1" className="form-label">Country</label>
                                    <input type="text" disabled="" className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      name="country"
                                      value={customerData.country}/>
                                  </div>


                                  <div className="col-lg-3">
                                    <label htmlFor="bsValidation1" className="form-label">Pincode</label>
                                    <input type="text"
                                      maxLength={6}
                                      className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      name="pincode"
                                      ref={customerPincodeRef}
                                      value={customerData.pincode} />
                                      { error.pincode && (
                                        <div className="error-message invalid-feedback" > 
                                          {error.pincode}
                                        </div>
                                      )}
                                  </div>

                                  <div className="col-lg-6">
                                    <label htmlFor="bsValidation1" className="form-label">Email</label>
                                    <input type="text" 
                                      className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      value={customerData.email}
                                      ref={customerEmailRef}
                                      name="email"/>

                                      { error.email && (
                                        <div className="error-message invalid-feedback" > 
                                          {error.email}
                                        </div>
                                      )}
                                  </div> 

                                  <div className="col-lg-6">
                                    <label htmlFor="bsValidation1" className="form-label">Reference ID</label>
                                    <input type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      value={customerData.referid}
                                      ref={customerRidRef}
                                      name="referid" />

                                    {error.referid && (
                                      <div className="error-message invalid-feedback" >
                                        {error.referid}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseOne" fdprocessedid="ysxib">
                                Business Details (Optional)
                              </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample" >
                              <div className="accordion-body">
                                <div className="row g-3">

                                  <div className="col-lg-4">
                                    <label htmlFor="bsValidation1" className="form-label">GST Number</label>
                                    <input type="text" className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      value={customerData.gstnumber}
                                      maxLength={15}
                                      ref={customerGSTRef}
                                      name="gstnumber"/>
                                      { error.gstnumber && (
                                        <div className="error-message invalid-feedback" > 
                                          {error.gstnumber}
                                        </div>
                                      )}
                                  </div>

                                  <div className="col-lg-4">
                                    <label htmlFor="bsValidation1" className="form-label">PAN Number</label>
                                    <input type="text" className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      maxLength={10}
                                      name="pannumber"
                                      ref={customerPanRef}
                                      value={customerData.pannumber}
                                    />
                                    { error.pannumber && (
                                        <div className="error-message invalid-feedback" > 
                                          {error.pannumber}
                                        </div>
                                      )}
                                  </div>

                                  <div className="col-lg-4">
                                    <label htmlFor="bsValidation1" className="form-label">CIN Number</label>
                                    <input type="text" className="form-control"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      maxLength={21}
                                      value={customerData.cinnumber}
                                      ref={customerCinRef}
                                      name="cinnumber"
                                    />
                                    { error.cinnumber && (
                                        <div className="error-message invalid-feedback" > 
                                          {error.cinnumber}
                                        </div>
                                      )}
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
                <div className="modal-footer d-block">
                    <button type="button" className="btn btn-outline-secondary text-left" onClick={handleClose}>
                        <i className="bx bx-x"></i> Cancel
                    </button>
                    {isbtnLoading ? (
                      <button className="btn btn-primary pb-0 text-right" disabled>
                        <CircularProgress size={22} className="text-white" /> 
                      </button>
                    ) : (
                      <button type="button" className="btn btn-primary text-right" 
                        onClick={handleEdit}>
                        <i className="ng-ng-add-icon"></i> Update Customer
                      </button>
                    )}
                </div>
            </div>
          </div>
      </div>
    </div>
  );
};



export default EditCutomer;
