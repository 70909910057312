import React, { useRef } from 'react';
import { CircularProgress } from "@mui/material";

const OtpStep = ({ email, otp, setOtp, onSubmit, loading, error }) => {
    const otpRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const handleOTPChange = (e, index) => {
        const value = e.target.value;
        const key = e.key;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);
            if (value && index < otpRefs.length - 1) {
                otpRefs[index + 1].current.focus();
            }
        }
        if (key === "Backspace" && !value && index > 0) {
            otpRefs[index - 1].current.focus();
        }
    };

    return (
        <div>
            <h5 className="text-center">OTP Verification</h5>
            <p className="text-center text-success">
                One Time Password (OTP) has been sent successfully to entered email.
            </p>
            <form className="row g-3" onSubmit={(e) => e.preventDefault()}>
                <div className="col-12">
                    <label className="form-label">Enter OTP</label>
                    <div className="d-flex justify-content-between">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                ref={otpRefs[index]}
                                type="text"
                                maxLength="1"
                                className="form-control text-center input-box"
                                value={digit}
                                onChange={(e) => handleOTPChange(e, index)}
                                onKeyDown={(e) => handleOTPChange(e, index)}
                                onFocus={(e) => e.target.select()}
                                autoComplete="off"
                                required
                            />
                        ))}
                    </div>
                    {error && <div className="invalid-feedback">{error}</div>}
                </div>
                <div className="col-12">
                    <div className="d-grid">
                        {loading ? (
                        <button className="btn btn-primary pb-0" disabled>
                            <CircularProgress size={22} className="text-white" />
                        </button>
                        ) : (
                        <button onClick={onSubmit} className="btn btn-primary">
                            Verify OTP
                        </button>
                        )}
                    </div>
                    <div className="d-grid mt-3">
                        <a href='/login' className="btn btn-light">
                            Back To Login
                        </a>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default OtpStep;