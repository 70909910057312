import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { UserContext } from "../../contexts/UserContext";

function Header({ toggleSideMenu }) {
  const { handleLogout } = useContext(AuthContext);
  const { user } = useContext(UserContext);

  const handleLogoutClick = () => {
    handleLogout();
  };

  return (
    <div>
      <div className="wrapper toggled">
        <header>
          <div className="topbar d-flex align-items-center">
            <nav className="navbar navbar-expand gap-3">
              <div className="topbar-logo-header d-none d-lg-flex">
                <img
                  src="/assets/images/v1/logo-img.png"
                  className="logo-icon"
                  alt="Logo"
                />
              </div>
              <div className="mobile-toggle-menu" onClick={toggleSideMenu}>
                <i className="bx bx-menu"></i>
              </div>
              <div className="top-menu ms-auto">
                <ul className="navbar-nav align-items-center gap-1">
                  {/* Add additional menu items here if needed */}
                </ul>
              </div>
              <div className="user-box dropdown px-3">
                <div className="dropdown">
                  <Link
                    to="#"
                    className="d-flex align-items-center nav-link dropdown-toggle gap-0 dropdown-toggle-nocaret"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/images/newlog.png"
                      className="user-img"
                      alt="User avatar"
                    />
                    <div className="user-info ms-3">
                      <p className="user-name mb-0">{user?.email || ""}</p>
                      {/* <p className="user-name mb-0">{user?.timezone || ""}</p> */}
                      <span className="text-white designation mb-0">{user?.stype || ""}</span>
                    </div>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/profile">
                        <i className="ng-ng-profile-icon"></i>
                        Profile
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={handleLogoutClick}
                      >
                        <i className="ng-ng-logout-icon"></i>
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;