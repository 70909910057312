import axios from "axios";
import { getVerifyToken } from "./CookieUtils";
import checkNetworkStatus from "../reusable/NetworkStatus";
import uRLs from "../utils/BaseUtils";
import ErrorMsg from "../messages/ErrorMessages";

export const sendEmailOTP = async (email, showAlert) => {
  checkNetworkStatus();
  try {
    const data = { email: email };
    const response = await axios.post(`${uRLs.AUTH_URL}auth-email`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.results.verifyToken;
  } catch (error) {
    if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
      showAlert("error", ErrorMsg.NO_INTERNET);
    } else if (error.response && error.response.data?.message) {
      showAlert("warning", error.response.data.message);
    } else {
      showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
    }
  }
};

export const verifyOtpCode = async (otpCode, showAlert) => {
  checkNetworkStatus();
  try {
    const data = { verifycode: otpCode };
    const token = getVerifyToken();
    if (!token) {
      showAlert("error", ErrorMsg.TOKEN_MISSING);
    }
    const response = await axios.post(`${uRLs.AUTH_URL}auth-email-otp`, data, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
    return response.data.results.accessToken;
  } catch (error) {
    if (error.message === "NO_INTERNET" || error.code === "ERR_NETWORK") {
      showAlert("error", ErrorMsg.NO_INTERNET);
    } else if (error.response && error.response.data?.message) {
      showAlert("warning", error.response.data.message);
    } else {
      showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
    }
  }
};
