import React, { useState } from "react";
import EditCutomer from "./EditCustomer";
import DeleteModal from "../reusable/DeleteModal";
import { Skeleton } from "@mui/material";

const ListCustomer = ({ isCustomerData, onEdit, onDelete, isbtnLoading, isLoading }) => {
  const [isCustData, setCustData] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-label-green";
      case "inactive":
        return "bg-label-danger";
      default:
        return "bg-label-secondary";
    }
  };

  const openEditModal = (cItem) => {
    setCustData(cItem);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCustData(null);
  };

  const handleUpdte = (updatedData) => {
    onEdit(updatedData);
    closeEditModal();
  };

  const openDeleteModal= (cItem) => {
    setCustData(cItem);
    setDeleteModalOpen(true);
  };

  const handleDelete = () => {
    onDelete(isCustData._id);
    setDeleteModalOpen(false);
  };

  return (<>
    <div className="table-responsive mt-3">
      <table className="table align-middle mb-0">
        <thead className="table-light text-center">
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>State</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            [...Array(10)].map((_, index) => (
              <tr key={index}>
                <td className="text-center">
                  <Skeleton variant="text" width={200} />
                </td>
                <td className="text-center">
                  <Skeleton variant="text" width={400} />
                </td>

                <td className="text-center">
                  <Skeleton variant="center" width={300} />
                </td>
                <td className="text-center">
                  <Skeleton variant="text" width={100} />
                </td>
                <td className="text-center">
                  <Skeleton variant="text" width={100} />
                </td>
                <td className="text-center">
                  <Skeleton variant="text" width={100} />
                </td>
                <td className="text-center">
                  <Skeleton variant="text" width={70} />
                </td>
              </tr>
            ))
          ) : Array.isArray(isCustomerData) &&
            isCustomerData.length > 0 ? (
            isCustomerData.map((item, index) => (
              <tr key={item.id || index}>
                <td className="text-center">{isCustomerData.length - index}</td>
                <td className="text-center">
                  <span className="text-heading fw-medium text-truncate">
                    {item.name}
                  </span>
                </td>
                <td className="text-center">{item.phone}</td>
                <td className="text-center">{item.email}</td>
                <td className="text-center">{item.state}</td>

                <td className="text-center">
                  {item.status && (
                    <span
                      className={`badge rounded-pill ${getColor(item.status)}`}>
                      {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
                    </span>
                  )}
                </td>
                <td className="text-center">
                  <div className="order-actions">
                  <button type="button" className="btn-label-primary shadow-none me-2" onClick={() => openEditModal(item)}>
                    <i className="bx bx-pencil"></i>
                  </button>
                    <button type="button" className="btn-label-danger shadow-none me-2" onClick={() => openDeleteModal(item)} >
                      <i className="bx bx-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center p-2">
                <span className="text-muted">No customers found in the store. add some customers to get started.</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>

    { isEditModalOpen && (
      <EditCutomer
        editData={isCustData}
        onClose={closeEditModal}
        onEdit={handleUpdte}
        isbtnLoading={isbtnLoading}
      />
    )}

    {isDeleteModalOpen && (
      <DeleteModal
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={ handleDelete }
        Name="Customer"
      />
    )}

  </>
  );
};

export default ListCustomer;
