import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import FullPageLoader from "../reusable/FullPageLoader";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  if (isLoading) {
    return <FullPageLoader isLoading={isLoading} />;
  }
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;